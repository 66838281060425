import 'bootstrap/dist/css/bootstrap.min.css'
import './style.css'
import { Container } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faYoutube, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { useContext } from 'react'
import { GeneralInfoContext } from 'context'
const Footer = (): JSX.Element => {
    const { youtubeChannelURL, wp_number, phoneNumber, email, footerSlogan, operatingHours } = useContext(GeneralInfoContext)
    let whatsappUrl = `https://wa.me/${wp_number}`;
    return (
        <div className='footer'>
            <p className="center bottom ending common_text" style={{ fontStyle: "italic", backgroundColor: "#fffff" }}>{footerSlogan}</p>
            <Container fluid>
                <div className='background-footer'>
                    <div className='mt-4'>
                        <div className="singleCol social-media-icons-white  d-flex justify-content-evenly" >
                            <a href={youtubeChannelURL} target='_blank'>
                                <FontAwesomeIcon icon={faYoutube} />
                            </a>
                            <a href={whatsappUrl} target='_blank'>
                                <FontAwesomeIcon icon={faWhatsapp} />
                            </a>
                        </div>
                        <div className='Columns'>
                            <div className='columnLeft'>
                                <h3>Contact</h3>
                                <p className='footText'><FontAwesomeIcon icon={faPhone} />{phoneNumber}</p>
                                <p className='footText'><FontAwesomeIcon icon={faEnvelope} />{email}</p>
                            </div>
                            <div className='columnRight'>
                                <div>
                                    <h3>Operating Hours</h3>
                                    {
                                        operatingHours.map((value,index)=>{
                                            return (<p key={index} className='footText'>{value}</p>)
                                        })
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


            </Container>


        </div>
    );
}

export default Footer;