import { ChangeEvent, useState } from "react";
import '../PagesContentManagement/styles.css';
import { uploadFiles } from "services";

type PagesInformationSectionProps = {
    about_main_image: string;
    contact_main_image: string;
    gallery_main_image: string;
    home_carousel_images: string[];
    home_info_section_description: string;
    home_info_section_title: string;
    home_transparent_background: string;
    services_main_image: string;
    hasChangesToSave: boolean;
    sendingData: boolean;
    handleChange: (property: string, value: any, index?: number) => void;
    handleSubmit: (data: object) => void;
}

const PagesInformationSection = (props: PagesInformationSectionProps) => {
    const {
        about_main_image,
        contact_main_image,
        gallery_main_image,
        home_carousel_images,
        home_info_section_description,
        home_info_section_title,
        home_transparent_background,
        services_main_image,
        hasChangesToSave,
        sendingData,
        handleChange,
        handleSubmit,
    } = props;


    const [newabout_main_image, setnewabout_main_image] = useState<any | null>(null);
    const [newcontact_main_image, setnewcontact_main_image] = useState<any | null>(null);
    const [newgallery_main_image, setnewgallery_main_image] = useState<any | null>(null);
    const [newhome_carousel_images, setnewhome_carousel_images] = useState<any[]>([]);
    const [removehome_carousel_images, setremovehome_carousel_images] = useState<any[]>([]);
    const [newhome_transparent_background, setnewhome_transparent_background] = useState<any | null>(null);
    const [newHomePageCarouselImg, setnewHomePageCarouselImg] = useState<any | null>(null);
    const [newservices_main_image, setnewservices_main_image] = useState<any | null>(null);

    const handleFileChange = (property: string, event: any) => {
        const file = event.target.files[0];
        switch (property) {
            case 'about_main_image': setnewabout_main_image(file); break;
            case 'contact_main_image': setnewcontact_main_image(file); break;
            case 'gallery_main_image': setnewgallery_main_image(file); break;
            case 'home_transparent_background': setnewhome_transparent_background(file); break;
            case 'services_main_image': setnewservices_main_image(file); break;
            case 'home_carousel_images': setnewHomePageCarouselImg(file); break;
        }
        handleChange('hasChangesToSave', true);
    }

    const handlePageInfoChange = (property: string, value: string | [] | File, index: number = -1, oper: string = "none", isNew: boolean = false) => {

        if (property === "home_carousel_images") {
            let newCarouselImages: string[] = [...home_carousel_images];
            if (oper === "add") {
                if (newHomePageCarouselImg) {
                    handleChange('hasChangesToSave', true);
                    setnewhome_carousel_images([...newhome_carousel_images, newHomePageCarouselImg]);
                    setnewHomePageCarouselImg(null);
                    (document.getElementById("newHomePageCarouselImg") as HTMLInputElement)!.value = '';
                }
            } else if (oper === "remove") {
                handleChange('hasChangesToSave', true);
                if (!isNew) {
                    const removedImage = newCarouselImages.splice(index, 1);
                    const newRemovedCarouselImages: string[] = [...removehome_carousel_images];
                    newRemovedCarouselImages.push(removedImage[0]);
                    setremovehome_carousel_images([...newRemovedCarouselImages]);
                    handleChange(property, [...newCarouselImages]);
                } else {
                    newCarouselImages = [...newhome_carousel_images];
                    newCarouselImages.splice(index, 1);
                    setnewhome_carousel_images([...newCarouselImages]);
                }
            }
        } else if (property === "selectedImg") {
            handleChange(property, isNew ? URL.createObjectURL(value as any) : value);
        } else {
            handleChange('hasChangesToSave', true);
            handleChange(property, value as string);
        }
    }
    const onChange = (event: ChangeEvent<HTMLInputElement>, property: string, index: number = 0) => {
        handlePageInfoChange(property, event.target.value, index)
    }

    const onSubmit = (event: any) => {
        event.preventDefault();
        handleChange('sendingData', true);
        const homeImages = home_carousel_images.map(homeImg => `hm_crl_img/${homeImg.substring(homeImg.lastIndexOf('/') + 1)}`);
        let objToSubmit: any = {
            data: {
                update: {
                    home_info_section_description,
                    home_info_section_title,
                    home_carousel_images: homeImages,
                }
            }
        };

        const filesToUpload = [];
        if (newabout_main_image) {
            objToSubmit = {
                ...objToSubmit, data: {
                    ...objToSubmit.data, update: {
                        ...objToSubmit.data.update, about_main_image: `abus_img_bg/${newabout_main_image.name}`
                    }
                }
            }
            filesToUpload.push({ file: newabout_main_image, path: 'abus_img_bg' });
        }
        if (newcontact_main_image) {
            objToSubmit = {
                ...objToSubmit, data: {
                    ...objToSubmit.data, update: {
                        ...objToSubmit.data.update, contact_main_image: `ctct_img_bg/${newcontact_main_image.name}`
                    }
                }
            }
            filesToUpload.push({ file: newcontact_main_image, path: 'ctct_img_bg' });
        }
        if (newgallery_main_image) {
            objToSubmit = {
                ...objToSubmit, data: {
                    ...objToSubmit.data, update: {
                        ...objToSubmit.data.update, gallery_main_image: `srv_gp_bg/${newgallery_main_image.name}`
                    }
                }
            }
            filesToUpload.push({ file: newgallery_main_image, path: 'srv_gp_bg' });
        }
        if (newhome_carousel_images.length > 0) {
            const newImages = newhome_carousel_images.map((value) => {
                return `hm_crl_img/${value.name}`;
            })
            
            objToSubmit = {
                ...objToSubmit, data: {
                    ...objToSubmit.data, update: {
                        ...objToSubmit.data.update,
                        home_carousel_images: [...homeImages, ...newImages],
                    }
                }
            }
            newhome_carousel_images.map((file: any) => {
                filesToUpload.push({ file: file, path: 'hm_crl_img' });
            })
        }
        if (removehome_carousel_images.length > 0) {
            const fixedServImagesToRemove = removehome_carousel_images.map(carouselImg => `hm_crl_img/${carouselImg.substring(carouselImg.lastIndexOf('/') + 1)}`);
            objToSubmit = {
                ...objToSubmit, data: {
                    ...objToSubmit.data, delete: {
                        ...objToSubmit.data.delete,
                        home_carousel_images: [...fixedServImagesToRemove],
                    }
                }
            }
        }
        if (newhome_transparent_background) {
            objToSubmit = {
                ...objToSubmit, data: {
                    ...objToSubmit.data, update: {
                        ...objToSubmit.data.update, home_transparent_background: `hm_img_bg/${newhome_transparent_background.name}`
                    }
                }
            }
            filesToUpload.push({ file: newhome_transparent_background, path: 'hm_img_bg' });
        }
        if (newservices_main_image) {
            objToSubmit = {
                ...objToSubmit, data: {
                    ...objToSubmit.data, update: {
                        ...objToSubmit.data.update, services_main_image: `srv_srvpg_bg_img/${newservices_main_image.name}`
                    }
                }
            }
            filesToUpload.push({ file: newservices_main_image, path: 'srv_srvpg_bg_img' });
        }

        uploadFiles(filesToUpload);
        handleSubmit(objToSubmit);
        setnewabout_main_image(null);
        setnewcontact_main_image(null);
        setnewgallery_main_image(null);
        setnewhome_carousel_images([]);
        setremovehome_carousel_images([]);
        setnewhome_transparent_background(null);
        setnewservices_main_image(null);

        (document.getElementById("new-about_main_image") as HTMLInputElement)!.value = '';
        (document.getElementById("new-contact_main_image") as HTMLInputElement)!.value = '';
        (document.getElementById("new-gallery_main_image") as HTMLInputElement)!.value = '';
        (document.getElementById("new-services_main_image") as HTMLInputElement)!.value = '';
        (document.getElementById("new-home_transparent_background") as HTMLInputElement)!.value = '';
    }

    return <section className='content-data'>
        <form onSubmit={onSubmit}>


            <div className='option-to-edit'>
                <h3 className='title2 gen-opt'>About us page image: </h3>
                <button type="button" className="custom-button" onClick={() => handleChange('selectedImg', about_main_image)}>See current page image</button>
                <input disabled={sendingData} accept="image/png, image/jpeg, image/jpg" type="file" onChange={(e) => handleFileChange('about_main_image', e)} id={'new-about_main_image'} />
            </div>

            <div className='option-to-edit'>
                <h3 className='title2 gen-opt'>Contact us page image: </h3>
                <button type="button" className="custom-button" onClick={() => handleChange('selectedImg', contact_main_image)}>See current page image</button>
                <input disabled={sendingData} accept="image/png, image/jpeg, image/jpg" type="file" onChange={(e) => handleFileChange('contact_main_image', e)} id={'new-contact_main_image'} />
            </div>

            <div className='option-to-edit'>
                <h3 className='title2 gen-opt'>Gallery page image: </h3>
                <button type="button" className="custom-button" onClick={() => handleChange('selectedImg', gallery_main_image)}>See current page image</button>
                <input disabled={sendingData} accept="image/png, image/jpeg, image/jpg" type="file" onChange={(e) => handleFileChange('gallery_main_image', e)} id={'new-gallery_main_image'} />
            </div>

            <div className='option-to-edit'>
                <h3 className='title2 gen-opt'>Services page image: </h3>
                <button type="button" className="custom-button" onClick={() => handleChange('selectedImg', services_main_image)}>See current page image</button>
                <input disabled={sendingData} accept="image/png, image/jpeg, image/jpg" type="file" onChange={(e) => handleFileChange('services_main_image', e)} id={'new-services_main_image'} />
            </div>

            <div className='option-to-edit'>
                <h3 className='title2 gen-opt'>Home transparent background image: </h3>
                <button type="button" className="custom-button" onClick={() => handleChange('selectedImg', home_transparent_background)}>See current page image</button>
                <input disabled={sendingData} accept="image/png, image/jpeg, image/jpg" type="file" onChange={(e) => handleFileChange('home_transparent_background', e)} id={'new-home_transparent_background'} />
            </div>
            <div className='option-to-edit'>
                <h3 className='title2 gen-opt'>Home page carousel images: </h3>
                <ol style={{ flexDirection: "column", alignItems: "flex-start" }}>
                    {
                        home_carousel_images.map((carouselImage, index) => {
                            const imageSections = carouselImage.split('/');
                            const currImageName = imageSections[imageSections.length - 1];
                            return <li key={index} style={{ listStyle: "none", marginTop: "5px", display: "flex", gap: "5px", alignItems: "flex-start" }}>
                                <button type="button" className="custom-button" onClick={() => handlePageInfoChange("selectedImg", carouselImage)}>See current carousel image</button>
                                <button className="custom-button" type="button" aria-label="remove" onClick={() => handlePageInfoChange('home_carousel_images', '', index, 'remove')}> X </button>
                                <h3 className='title2 gen-opt'>{currImageName}</h3>
                            </li>
                        })
                    }
                    <li style={{ listStyle: "none", marginTop: "15px", marginBottom: "15px", display: "flex", gap: "5px", alignItems: "flex-start" }}>
                        <button disabled={newHomePageCarouselImg === null} className="custom-button" type="button" aria-label="add new" onClick={() => handlePageInfoChange('home_carousel_images', '', -1, 'add')}> Add new</button>
                        <input disabled={sendingData} accept="image/png, image/jpeg, image/jpg" type="file" onChange={(e) => handleFileChange('home_carousel_images', e)} id={'newHomePageCarouselImg'} />
                    </li>
                    {
                        newhome_carousel_images.length > 0 && (
                            <h3 className='title2 gen-opt'>New images to add: </h3>
                        )
                    }
                    {
                        newhome_carousel_images.map((carouselImage, index) => {
                            const currImageName = carouselImage.name;
                            return <li key={index} style={{ listStyle: "none", marginTop: "5px", display: "flex", gap: "5px", alignItems: "flex-start" }}>
                                <button type="button" className="custom-button" onClick={() => handlePageInfoChange("selectedImg", carouselImage, -1, "none", true)}>See new carousel image</button>
                                <button className="custom-button" type="button" aria-label="remove" onClick={() => handlePageInfoChange('home_carousel_images', '', index, 'remove', true)}> X </button>
                                <h3 className='title2 gen-opt' style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{currImageName}</h3>
                            </li>
                        })
                    }
                </ol>
            </div>
            <div className='option-to-edit'>
                <h3 className='title2 gen-opt'>Title in the home page mirror section: </h3>
                <input disabled={sendingData} type="text" value={home_info_section_title} placeholder="Title text of the Home mirror page section" onChange={(e) => onChange(e, "home_info_section_title")} />
            </div>
            <div className='option-to-edit'>
                <h3 className='title2 gen-opt'>Content in the home page mirror section: </h3>
                <input disabled={sendingData} type="text" value={home_info_section_description} placeholder="Content text of the Home mirror page section" onChange={(e) => onChange(e, "home_info_section_description")} />
            </div>
            <div style={{ display: "flex", gap: "5px", paddingTop: "5px" }}>
                <button disabled={!hasChangesToSave || sendingData} className='custom-button' type='submit'>Save Changes</button>
            </div>
        </form>
    </section>
}

export default PagesInformationSection;