import axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';;

const base_url = process.env.REACT_APP_URL || '';
const baseAuthUrl = process.env.REACT_APP_AUTH_URL || '';

const getHeaders = () => {
  return {
    headers: {
      'Content-Type': 'application/json'
    }
  }
}

const getAuthHeaders = async () => {
  const { idToken } = (await fetchAuthSession()).tokens ?? {};
  return {
    headers: {
      'Authorization': `Bearer ${idToken}`
    }
  }
}

export async function getGeneralInfo() {
  try {
    const body = { endpoint: "g/g_i" };
    const restOperation = await axios.post(base_url, body, getHeaders());
    const parsedAnswer = JSON.parse(restOperation.data.body);
    return JSON.parse(parsedAnswer.body);
  } catch (error) {
    console.log('GET call failed: ');
  }
}

export async function getHomeInfo() {
  try {
    const body = { endpoint: "g/p_i" };
    const restOperation = await axios.post(base_url, body, getHeaders());
    const parsedAnswer = JSON.parse(restOperation.data.body);
    return JSON.parse(parsedAnswer.body);
  } catch (error) {
    console.log('GET call failed: ');
  }
}

export async function getCompanyInfo() {
  try {
    const body = { endpoint: "g/c_i" };
    const restOperation = await axios.post(base_url, body, getHeaders());
    const parsedAnswer = JSON.parse(restOperation.data.body);
    return JSON.parse(parsedAnswer.body);
  } catch (error) {
    console.log('GET call failed: ');
  }
}

export async function getServicesInfo() {
  try {
    const body = { endpoint: "g/s_v" };
    const restOperation = await axios.post(base_url, body, getHeaders());
    const parsedAnswer = JSON.parse(restOperation.data.body);
    return JSON.parse(parsedAnswer.body);
  } catch (error) {
    console.log('GET call failed: ');
  }
}

export async function sendReview(bodyParameters: { user_name: string; review: string; qualification: string; }) {
  try {
    const body = {
      endpoint: "po/r_v",
      data: bodyParameters
    };
    const restOperation = await axios.post(base_url, body, getHeaders());
    const parsedAnswer = JSON.parse(restOperation.data.body);
    return JSON.parse(parsedAnswer.body);
  } catch (error) {
    console.log('POST call failed: ');
  }
}

export async function contactAdmin(bodyParameters: { name: string; phone: string; email: string; message: string }) {
  try {
    const body = {
      endpoint: "po/c_e",
      data: bodyParameters
    };
    const restOperation = await axios.post(base_url, body, getHeaders());
    const parsedAnswer = JSON.parse(restOperation.data.body);
    return JSON.parse(parsedAnswer.body);
  } catch (error) {
    console.log('POST call failed: ');
  }
}

export async function getReviewsToManage() {
  try {
    const authHeaders = await getAuthHeaders();
    const body = {
      endpoint: "g/r_d"
    };
    const restOperation = await axios.post(baseAuthUrl, body, authHeaders);
    return JSON.parse(restOperation.data.body);
  } catch (err: any) {
    if (err.request.status === 401) {
      return []
    }
  }
}

export async function manageReviewStatus(bodyParameters: { "action": string; "id": string; }) {
  try {
    const authHeaders = await getAuthHeaders();
    const body = {
      endpoint: "pu/r_v",
      data: bodyParameters
    };
    const restOperation = await axios.post(baseAuthUrl, body, authHeaders);
    return JSON.parse(restOperation.data.body);
  } catch (err: any) {
    if (err.request.status === 401) {
      return []
    }
  }
}

export async function getContentData() {
  try {
    const authHeaders = await getAuthHeaders();
    const body = {
      endpoint: "g/g_d"
    };
    const restOperation = await axios.post(baseAuthUrl, body, authHeaders);
    return JSON.parse(restOperation.data.body);
  } catch (err: any) {
    console.log('ERR', err)
    if (err.request.status === 401) {
      return {};
    }
  }
}

export async function manageData(bodyParameters: any) {
  try {
    const authHeaders = await getAuthHeaders();
    const body = {
      endpoint: "pu/g_d",
      data: bodyParameters
    };
    const restOperation = await axios.post(baseAuthUrl, body, authHeaders);
    return JSON.parse(restOperation.data.body);
  } catch (err: any) {
    if (err.request.status === 401) {
      return []
    }
  }
}

async function getUrl(key: string) {
  const signedUrlParams = {
    key
  };
  const authHeaders = await getAuthHeaders();
  const body = {
    endpoint: "po/u_s",
    data: signedUrlParams
  };
  const restOperation = await axios.post(baseAuthUrl, body, authHeaders);
  return JSON.parse(restOperation.data.body);
}

export async function uploadFile(file: any, path: string) {
  try {
    const restOperation = await axios.put(path,
      file, {
      headers: {
        'Content-Type': file.type,
      },
    });
    return restOperation.status;
  } catch (error) {
    console.log('Error uploading file ',error);
  }
}

export async function uploadFiles(filesToUpload: any[]) {
  filesToUpload.forEach(async (value: { path: string, file: File }) => {
    const key = `${value.path}/${value.file.name}`
    const signedUrl = await getUrl(key);
    await uploadFile(value.file, signedUrl.url);
  });
}