import { ReactNode } from "react";
import "./styles.css"

// Define the type for your props
interface ModalProps {
  // Define the props that your component will receive
  // For example, you might have an imageURL prop
  children: ReactNode;
  onClose?: ()=>void;
}

// Use the defined type for the props
const Modal: React.FC<ModalProps> = (props) => {
  return (
    <div className="backdrop" onClick={props.onClose}>
      {props.children}
    </div>
  );
};

export default Modal;