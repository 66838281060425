import { ChangeEvent } from "react";
import '../PagesContentManagement/styles.css';

type CompanyInformationSectionProps = {
    mision: string;
    vision: string;
    principalObjective: string;
    specificObjectives: string[];
    welcomeMessage: string;
    hasChangesToSave: boolean;
    sendingData: boolean;
    handleChange: (property: string, value: any, index?: number) => void;
    handleSubmit: (data: object) => void;
}

const CompanyInformationSection = (props: CompanyInformationSectionProps) => {
    const {
        hasChangesToSave,
        sendingData,
        mision,
        vision,
        principalObjective,
        specificObjectives,
        welcomeMessage,
        handleChange,
        handleSubmit,
    } = props;
    const handleCompInfoChange = (property: string, value: string | [], index: number = 0, oper: string = "none") => {
        handleChange('hasChangesToSave', true);
        if (property === "specificObjectives") {
            let newSpecificObjectives = specificObjectives;
            if (oper === "none") {
                newSpecificObjectives[index] = value as string;
            } else if (oper === "add") {
                newSpecificObjectives.push(value as string);
            } else if (oper === "remove") {
                newSpecificObjectives.splice(index, 1);
            }
            handleChange(property, [...newSpecificObjectives]);
        } else {
            handleChange(property, value as string);
        }
    }
    const onChange = (event: ChangeEvent<HTMLInputElement>, property: string, index: number = 0, oper: string = "none") => {
        handleCompInfoChange(property, event.target.value, index, oper)
    }

    const onSubmit = (event: any) => {
        event.preventDefault();
        const objToSubmit = {
            data: {
                update: {
                    mision: mision,
                    vision: vision,
                    principal_objective: principalObjective,
                    specific_objectives: specificObjectives,
                    welcome_message: welcomeMessage,
                }
            }
        };
        handleSubmit(objToSubmit);
    }

    return <section className='content-data'>
        <form onSubmit={onSubmit}>
            <div className='option-to-edit'>
                <h3 className='title2 gen-opt'>Welcome Message: </h3>
                <input disabled={sendingData} type="text" value={welcomeMessage} placeholder="Wellcome Message in About us page" onChange={(e) => onChange(e, "welcomeMessage")} />
            </div>
            <div className='option-to-edit'>
                <h3 className='title2 gen-opt'>Mision: </h3>
                <input disabled={sendingData} type="text" value={mision} placeholder="Mision" onChange={(e) => onChange(e, "mision")} />
            </div>
            <div className='option-to-edit'>
                <h3 className='title2 gen-opt'>Vision: </h3>
                <input disabled={sendingData} type="text" value={vision} placeholder="Vision" onChange={(e) => onChange(e, "vision")} />
            </div>
            <div className='option-to-edit'>
                <h3 className='title2 gen-opt'>Principal Objective: </h3>
                <input disabled={sendingData} type="text" value={principalObjective} placeholder="Principal Objective" onChange={(e) => onChange(e, "principalObjective")} />
            </div>
            <div className='option-to-edit'>
                <h3 className='title2 gen-opt'>Specific Objectives: </h3>
                <ol>
                    {
                        specificObjectives.map((value: any, index) => {
                            return <li key={index} style={{ display: "flex", gap: "5px", margin: "5px 0" }}>
                                <input disabled={sendingData} type="text" value={value} placeholder="Specific Objectives" onChange={(e) => onChange(e, "specificObjectives", index)} required />
                                <button className="custom-button" type="button" aria-label="remove" onClick={()=>handleCompInfoChange('specificObjectives','',index,"remove")}> X </button>
                            </li>
                        })
                    }
                    <li style={{ listStyle: "none" }}>
                        <button className="custom-button" type="button" onClick={()=>handleCompInfoChange('specificObjectives','',-1,"add")}> Add New</button>
                    </li>
                </ol>
            </div>
            <div style={{ display: "flex", gap: "5px", paddingTop: "5px" }}>
                <button disabled={!hasChangesToSave || sendingData} className='custom-button' type='submit'>Save Changes</button>
            </div>
        </form>
    </section>
}

export default CompanyInformationSection;