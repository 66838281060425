

import AboutUs from 'pages/AboutUs';
import ContactUs from 'pages/ContactUs';
import Home from 'pages/Home';
import Services from 'pages/Services';
import Gallery from 'pages/Gallery';
import Layout from 'containers/Layout';
import './style.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Authenticator from 'containers/Authenticator';
import Management from 'pages/Management';

const App = (): JSX.Element => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route
                        index
                        element={<Home />}
                    />
                    <Route
                        path="/services"
                        element={<Services />
                        }
                    />
                    <Route
                        path="/gallery"
                        element={<Gallery />
                        }
                    />
                    <Route
                        path="/contact"
                        element={<ContactUs />
                        }
                    />
                    <Route
                        path="/about"
                        element={<AboutUs />
                        }
                    />
                    <Route
                        path="/management"
                        element={<Management />
                        }
                    />
                    <Route path="*" element={<Home />} />
                </Route>
                <Route
                    path="/login"
                    element={<Authenticator />
                    }
                />
            </Routes>
        </BrowserRouter>
    );
}

export default App;