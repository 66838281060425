import 'bootstrap/dist/css/bootstrap.min.css'
import './style.css'
import FadeInSection from 'containers/FadeInSection';
import { useCallback, useContext, useEffect, useState } from 'react';
import { GeneralInfoContext } from 'context';
import { getCompanyInfo } from 'services';
//import  '../AboutUs/images/truck.jpg'
const AboutUs = (): JSX.Element => {
  const [wasFetched, setWasFetched] = useState(false);
  const [mission,setMission]=useState<string>('');
  const [vision,setVision]=useState<string>('');
  const [generalObjective,setGeneralObjective]=useState<string>('');
  const [specifictObjectives,setSpecificObjectives]=useState<[]>([]);
  const {wellcomeMessage, aboutMainImage} = useContext(GeneralInfoContext);
  const getAppInfo = useCallback(async () => {
    const request = await getCompanyInfo();
    setMission(request.mision);
    setVision(request.vision);
    setGeneralObjective(request.principal_objective);
    setSpecificObjectives(request.specific_objective);
    setWasFetched(true)
}, []);
useEffect(() => {
    if (!wasFetched) {
        getAppInfo();
    }
}, []);
  const objectivesList = specifictObjectives.map((objective, index) => (
    <li className='common_text' key={index}>{objective}</li>
  ));  
  const backgroundImageStyle = {
    backgroundImage: `url("${aboutMainImage}")`,
  }
  return (
    <div className="about-us-container">

      <FadeInSection>
        <div className='background-image' style={{...backgroundImageStyle}}>
          <div className="overlay"></div>

          <h1 className="white-text">About Us</h1>
        </div>
      </FadeInSection>

      <FadeInSection fadeInX>
        <p className="center common_text" >{wellcomeMessage}</p>
      </FadeInSection>
      <div className="two-column">
        <div className="left-column">

          <FadeInSection fadeInX>
            <h2 className='title2'>Mission</h2>
            <p className='common_text'>{mission}</p>
            <h2 className='title2'>Vision</h2>
            <p className='common_text'>{vision}</p>
          </FadeInSection>
        </div>
        <div className="right-column">

          <FadeInSection>
            <h2 className='title2'>Principal Objective</h2>
            <p className='common_text'>{generalObjective}</p>
            <h2 className='title2'>Specific Objective</h2>
            <ol>{objectivesList}</ol>
          </FadeInSection>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;