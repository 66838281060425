import { useCallback, useContext, useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import './styles.css';
import Modal from '../../components/Modal';
import FadeInSection from 'containers/FadeInSection';
import { getServicesInfo } from 'services';
import { GeneralInfoContext } from 'context';

const Gallery = () => {
  const [wasFetched, setWasFetched] = useState(false);
  const [services, setServices] = useState<{ service: string; gallery_pics: string[]; }[]>([]);
  const [selectedImg, setSelectedImg] = useState<string | null>("");
  const [selectedService, setSelectedService] = useState<string | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [serviceImages, setServiceImages] = useState<string[]>([]);
  const { galleryMainImage } = useContext(GeneralInfoContext);
  const getAppInfo = useCallback(async () => {
    const request = await getServicesInfo();
    const services = request.map((service: { service_name: string; gallery_page_images: []; }) => {
      return {
        service: service.service_name,
        gallery_pics: service.gallery_page_images
      }
    });
    setServices(services);
    setWasFetched(true);
  }, []);
  useEffect(() => {
    if (!wasFetched) {
      getAppInfo();
    }
  }, []);

  useEffect(() => {
    if (services.length > 0) {
      setSelectedService(services[currentIndex].service)
      setServiceImages(services[currentIndex].gallery_pics)
    }
  }, [services, currentIndex])

  const changeSelectedService = (movement: number) => {
    const nextIndex = currentIndex + movement;
    const fixedIndex = nextIndex < 0 ? services.length - 1 :
      nextIndex >= services.length ? 0 :
        nextIndex;
    setCurrentIndex(fixedIndex);
  };
  const openModal = (img: string) => {
    setSelectedImg(img)
    document.body.style.overflow = 'hidden';
  }
  const closeModal = () => {
    setSelectedImg(null);
    document.body.style.overflow = 'auto';
  }
  const backgroundImageStyle = {
    backgroundImage: `url("${galleryMainImage}")`,
  }
  return (
    <div className='gallery-container'>
      <FadeInSection >
        <div className='background-image' style={{ ...backgroundImageStyle }}>
          <div className="overlay"></div>

          <h1 className="white-text">Gallery</h1>
        </div>
      </FadeInSection>

      <div className='full-column'>

        <FadeInSection fadeInX>
          <div className='movements-container'>
            <button className="movement" onClick={() => changeSelectedService(1)}>{` < `}</button>
            <FadeInSection alwaysFade key={selectedService}>
              <h2 className='selected-service'>
                {selectedService}
              </h2>
            </FadeInSection>
            <button className="movement" onClick={() => changeSelectedService(-1)}>{` > `}</button>
          </div>
        </FadeInSection>
        {
          selectedService && (
            <div className='img-grid'>
              {serviceImages.map((image: string, index: number) => (
                <FadeInSection key={`${image} ${index}`}>
                  <div className='img-wrap'>
                    <img src={image} onClick={() => openModal(image)} />
                  </div>
                </FadeInSection>
              ))}
            </div>
          )
        }

        <div onClick={closeModal}>
          {selectedImg && <Modal><img src={selectedImg} alt="Modal Content" /></Modal>}
        </div>
      </div >
    </div >
  );
};


export default Gallery;  