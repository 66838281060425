import { getCurrentUser } from 'aws-amplify/auth';
import FadeInSection from 'containers/FadeInSection';
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReviewsManagement from 'containers/ReviewsManagement';
import PagesContentManagement from 'containers/PagesContentManagement';
import './styles.css';

const tabOptions = [
    { option: "reviews", content: <ReviewsManagement /> },
    { option: "Pages content", content: <PagesContentManagement /> }
];


const Management = () => {
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const navigate = useNavigate();
    const checkIfUserIsLogged = useCallback(async () => {
        try {
            const currUser = await getCurrentUser()
            if (currUser === undefined) {
                navigate("/login");
            }
        } catch {
            navigate("/login");
        }
    }, [])

    useEffect(() => {
        checkIfUserIsLogged();
    }, [])


    const handleTabChange = (index: number) => {
        setActiveTabIndex(index);
    }

    return (
        <div className='admin-container'>
            <h3 style={{ marginTop: "20px" }} className="title2">Management Panel</h3>
            <FadeInSection fadeInX>
                <div className='admin-tab-options' style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    padding: "20px",
                    gap: "20px"
                }}>
                    {
                        tabOptions.map((value, index) => {
                            return <button className={`custom-button ${index !== activeTabIndex ? 'no-selected-option' : ''}`} key={index}
                                onClick={() => handleTabChange(index)}>
                                {value.option.toLocaleUpperCase()}
                            </button>
                        })
                    }
                </div>
            </FadeInSection>
            <FadeInSection key={activeTabIndex}>
                <section className='admin-tab-content'>
                    {
                        tabOptions[activeTabIndex].content
                    }
                </section>
            </FadeInSection>
        </div>
    );
}

export default Management;