// Carousel.js

import React from 'react';
import Slider from 'react-slick';
import './style.css';

const Carousel = ({ images }: { images: { imageAlt: string; imageUrl: string }[] }) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: images.length < 3 ? images.length : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    dots: true,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: images.length < 3 ? images.length :3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: images.length < 2 ? 1 : 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    
  };
  return (
    <div className="image-carousel-container">
      <Slider {...settings}>
        {images.map(({ imageUrl, imageAlt }: any, index: React.Key) => {
          return (
            <div key={index}>
              <img src={imageUrl} alt={imageAlt} />
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default Carousel;
