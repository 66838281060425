import './style.css';

type StarRatingPropTypes = {
  title: string;
  description: string;
  rating: number;
  isCard: boolean;
  handleRatingChange?: (newRating:number)=> void;
}

const RatingContent = ({ description }: { description: string }) => {
  return (
        <p className="star-description common_text" style={{gap: "10px"}}>
          {`    ${description}    `}
        </p>
  )
}


const renderStars = (rating: number, handleRatingChange?: (value:number)=>void) => {
  const stars = [];
  for (let i = 1; i <= 5; i++) {
    stars.push(
      <span
        key={i}
        className={`star ${i <= rating ? 'filled' : ''}`}
        onClick={()=>{ handleRatingChange && handleRatingChange(i)}}
      >
        &#9733;
      </span>
    );
  }
  return stars;
};

const StarRating = ({ isCard, rating, title, description, handleRatingChange }: StarRatingPropTypes) => {


  const calculateProgressBarWidth = () => {
    return (rating / 5) * 100 + '%';
  };
  const card_class = isCard ? '__card' : '';
  return (
    <div className={`star-rating  ${card_class}`}>
      <h2 className='title2'>{title}</h2>
      <div className="stars">{renderStars(rating, handleRatingChange)}</div>
      {
        description.length > 0 ? <RatingContent description={description} /> : null
      }
      {
        !isCard && (
          <>
            <div className="progress-bar" style={{ width: calculateProgressBarWidth() }} />
          </>
        )
      }
    </div>
  );
};

export default StarRating;
