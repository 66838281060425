export default {
    Auth: {
        Cognito: {
            userPoolClientId: '7locod5vtvbui5cp9dvbl7flvb',
            userPoolId: 'us-east-2_95ZbQiU7G',
            loginWith: {
                email: true,
            },
        }
    }
}