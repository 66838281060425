import { GeneralInfoContext } from "context";
import { useCallback, useEffect, useState } from "react";
import { getGeneralInfo } from 'services';

const GeneralInfo = ({ children }: { children: JSX.Element }) => {

  const [wasFetched, setWasFetched] = useState(false);
  const [contactUsLogo, setContactUsLogo] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [footerSlogan, setFooterSlogan] = useState<string>('');
  const [homeYoutubeURL, setYoutubeURL] = useState<string>('');
  const [mapURL, setMapURL] = useState<string>('');
  const [navbarLogo, setNavbarLogo] = useState<string>('');
  const [operatingHours, setOperatingHours] = useState<[]>([]);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [talkWithUs_logo, setTalkWithUs_logo] = useState<string>('');
  const [wp_number,setWp_number] = useState<string>('');
  const [youtubeChannelURL, setYoutubeChannelURL] = useState<string>('');
  const [homeYoutubeVideoUrl, setHomeYoutubeVideoUrl] = useState<string>('');
  const [wellcomeMessage, setWellcomeMessage] = useState<string>('');
  const [contactMainImage, setContactMainImage] = useState<string>('');
  const [aboutMainImage, setAboutMainImage] = useState<string>('');
  const [serviceMainImage, setServiceMainImage] = useState<string>('');
  const [galleryMainImage, setGalleryMainImage] = useState<string>('');
  const getAppInfo = useCallback(async () => {
    const request = await getGeneralInfo();
    setContactUsLogo(request.contactus_logo);
    setEmail(request.email);
    setFooterSlogan(request.footer_slogan);
    setYoutubeURL(request.homeYoutubeVideoUrl);
    setMapURL(request.map_url);
    setNavbarLogo(request.navbarlogo);
    setOperatingHours(request.operating_hours);
    setPhoneNumber(request.phone_number);
    setTalkWithUs_logo(request.talkWithUs_logo);
    setWp_number(request.whatsapp);
    setYoutubeChannelURL(request.youtubeChannelUrl);
    setHomeYoutubeVideoUrl(request.homeYoutubeVideoUrl);
    setWellcomeMessage(request.welcome_message);
    setContactMainImage(request.contact_main_image);
    setAboutMainImage(request.about_main_image);
    setServiceMainImage(request.services_main_image);
    setGalleryMainImage(request.gallery_main_image);
    setWasFetched(true)
  }, []);
  useEffect(() => {
    if (!wasFetched) {
      getAppInfo();
    }
  }, []);

  const contextValues = {
    contactUsLogo,
    email,
    footerSlogan,
    homeYoutubeURL,
    mapURL,
    navbarLogo,
    operatingHours,
    phoneNumber,
    talkWithUs_logo,
    wp_number,
    youtubeChannelURL,
    homeYoutubeVideoUrl,
    wellcomeMessage,
    contactMainImage,
    aboutMainImage,
    serviceMainImage,
    galleryMainImage,
  }

  return (
    <GeneralInfoContext.Provider value={{ ...contextValues }}>
      {children}
    </GeneralInfoContext.Provider>
  )
}

export default GeneralInfo;