import { useState } from "react";
import '../PagesContentManagement/styles.css';
import NewServiceOption from "./components/NewServiceOption";
import FadeInSection from "containers/FadeInSection";
import UpdateServicesOption from "./components/UpdateServicesOption";
import { ServiceType } from "./components/UpdateServicesOption/components/ServiceOption";
import { uploadFiles } from "services";

type ServicesInformationSectionProps = {
    activeIndex: number;
    newServiceName: string;
    newServiceHomePageDescription: string;
    newServicePageDescription: string;
    newservice_images: any[];
    newgallery_images: any[];
    updservice_images: any[];
    updgallery_images: any[];
    serv_images_to_remove: string[];
    gall_images_to_remove: string[];
    services: ServiceType[];
    hasChangesToSave: boolean;
    sendingData: boolean;
    handleChange: (property: string, value: any, index?: number) => void;
    handleSubmit: (data: object) => void;
}

const ServicesInformationSection = (props: ServicesInformationSectionProps) => {
    const {
        activeIndex,
        newServiceName,
        newServiceHomePageDescription,
        newServicePageDescription,
        newservice_images,
        newgallery_images,
        updservice_images,
        updgallery_images,
        serv_images_to_remove,
        gall_images_to_remove,
        services,
        hasChangesToSave,
        sendingData,
        handleChange,
        handleSubmit,
    } = props;

    const [addNewService, setaddNewService] = useState(false);

    const onSubmit = async (event: any) => {
        event.preventDefault();
        handleChange('sendingData', true);
        let objToSubmit: any = {
            data: {
                update: {
                    services: services,
                }
            }
        };
        services.forEach((service, index) => {
            const fixedServImgs = service.service_page_images.map(servImg => `serv_srpg_img/${servImg.substring(servImg.lastIndexOf('/') + 1)}`);
            const fixedGallImgs = service.gallery_page_images.map(gallImg => `serv_glpg_img/${gallImg.substring(gallImg.lastIndexOf('/') + 1)}`);
            objToSubmit = {
                ...objToSubmit, data: {
                    ...objToSubmit.data, update: {
                        ...objToSubmit.data.update,
                        services:
                            [
                                ...services as any,
                                services[index].service_page_images = [...fixedServImgs, ...updservice_images[index].map((value: File) => `serv_srpg_img/${value.name}`)],
                                services[index].gallery_page_images = [...fixedGallImgs, ...updgallery_images[index].map((value: File) => `serv_glpg_img/${value.name}`)]
                            ]
                    }
                }
            }
        })
        if (serv_images_to_remove.length > 0) {
            const fixedServImagesToRemove = serv_images_to_remove.map(servImg => `serv_srpg_img/${servImg.substring(servImg.lastIndexOf('/') + 1)}`);
            objToSubmit = {
                ...objToSubmit, data: {
                    ...objToSubmit.data,
                    delete: {
                        service_page_images: [...fixedServImagesToRemove],
                    }
                }
            }
        }
        if (gall_images_to_remove.length > 0) {
            const fixedGallImagesToRemove = gall_images_to_remove.map(gallImg => `serv_glpg_img/${gallImg.substring(gallImg.lastIndexOf('/') + 1)}`);
            objToSubmit = {
                ...objToSubmit, data: {
                    ...objToSubmit.data,
                    delete: {
                        ...objToSubmit.data.delete,
                        gallery_page_images: [...fixedGallImagesToRemove],
                    }
                }
            }
        }
        const filesToUpload = []
        for (let i = 0; i < updservice_images.length; i++) {
            for (let j = 0; j < updservice_images[i].length; j++) {
                filesToUpload.push({ file: updservice_images[i][j], path: 'serv_srpg_img' });
            }
        }
        for (let i = 0; i < updgallery_images.length; i++) {
            for (let j = 0; j < updgallery_images[i].length; j++) {
                filesToUpload.push({ file: updgallery_images[i][j], path: 'serv_glpg_img' });
            }
        }
        uploadFiles(filesToUpload);
        handleSubmit(objToSubmit);
    }

    return <section className='content-data'>
        {
            addNewService ? (
                <NewServiceOption
                    newServiceName={newServiceName}
                    newServiceHomePageDescription={newServiceHomePageDescription}
                    newServicePageDescription={newServicePageDescription}
                    newservice_images={newservice_images}
                    newgallery_images={newgallery_images}
                    onCancel={() => {
                        setaddNewService(false);
                        handleChange('hasChangesToSave', false);
                        handleChange('newServiceName', '');
                        handleChange('newServiceHomePageDescription', '');
                        handleChange('newServicePageDescription', '');
                        handleChange('newservice_images', []);
                        handleChange('newgallery_images', []);
                    }}
                    hasChangesToSave={hasChangesToSave}
                    handleChange={handleChange}
                    sendingData={sendingData}
                    handleSubmit={handleSubmit} />
            ) : (
                <FadeInSection>
                    <button disabled={sendingData} className='custom-button' type='submit' onClick={() => setaddNewService(true)}>Add new service</button>
                    <form onSubmit={onSubmit}>
                        <UpdateServicesOption
                            updservice_images={updservice_images}
                            updgallery_images={updgallery_images}
                            serv_images_to_remove={serv_images_to_remove}
                            gall_images_to_remove={gall_images_to_remove}
                            activeIndex={activeIndex}
                            services={services}
                            handleChange={handleChange} />
                        <div style={{ display: "flex", gap: "5px", paddingTop: "5px" }}>
                            <button disabled={!hasChangesToSave || sendingData} className='custom-button' type='submit'>Save Changes on Services</button>
                        </div>
                    </form>
                </FadeInSection>
            )
        }
    </section>
}

export default ServicesInformationSection;