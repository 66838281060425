import React from "react";
import Slider from "react-slick";
import slider1 from "./images/slider_img_1.jpg";
import slider2 from "./images/slider2.jpeg";
import slider3 from "./images/slider3.jpeg";
import slider4 from "./images/slider4.jpeg";
import "./style.css";
type HomeCarouselPropTypes = {
  images: [];
}

const HomePageCarousel = (props:HomeCarouselPropTypes) => {
  const sliderImages = props.images;

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: sliderImages.length<3 ? sliderImages.length : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000, // Adjust autoplay speed in milliseconds
    arrows: false,
    dots: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: sliderImages.length<3 ? sliderImages.length : 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: sliderImages.length<2 ? 1 : 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div style={{ maxWidth: "100%", overflow: "hidden", position: "relative", backgroundColor: "#002B36" }}>
      <Slider {...settings}>
        {sliderImages.map((value, index) => (
          <div key={index} className="slider-item">
            <div className="overlay"></div>
            <img
              src={value}
              alt={`Image ${index + 1}`}
              className="slider-image"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default HomePageCarousel;
