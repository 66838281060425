import { getReviewsToManage, manageReviewStatus } from 'services';
import './styles.css';
import { useCallback, useEffect, useState } from 'react';
import FadeInSection from 'containers/FadeInSection';

type reviewProps = {
    idReview: number;
    qualification: number;
    review: string;
    review_status: string;
    user_name: string;
}

type reviewFixedProps = {
    id: number;
    qualification: number;
    content: string;
    status: string;
    user: string;
}

type reviewManagementTableProps = {
    reviews: reviewFixedProps[];
    actions: [];
    disabled: boolean;
    loading: boolean;
}

const NoReviewData = ({ loading }: { loading: boolean }) => {
    return <div className='no-review-data'>
        {loading ? 'loading...' : 'There are no reviews in this state.'}
    </div>
}

const ReviewsManagementTable = (props: reviewManagementTableProps) => {
    const { reviews, actions, disabled, loading } = props;
    if (reviews.length === 0) {
        return <NoReviewData loading={loading} />
    }
    return <>
        {
            reviews.map((review, index) => {
                return (
                    <FadeInSection key={index}>
                        <div className='review-data'>
                            <div>
                                <div className='title2'>
                                    <p>Name:  {review.user}</p>
                                </div>
                            </div>
                            <div>
                                <div className='title2'>
                                    <p>Qualification:  {review.qualification}</p>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <p>
                                        {review.content}
                                    </p>
                                </div>
                            </div>
                            <div style={{ paddingTop: "10px", display: "flex", gap: "10px" }}>
                                {
                                    actions.map((action: any, index) => {
                                        return <button
                                            className={`custom-button ${action.value} ${disabled ? 'disabled' : ''}`}
                                            key={index}
                                            disabled={disabled}
                                            onClick={() => action.action(review.id)}>
                                            {action.value}
                                        </button>
                                    })
                                }
                            </div>
                        </div>
                    </FadeInSection>
                )
            })
        }
    </>
}

const ReviewsManagement = () => {

    const [pendingReviews, setPendingReviews] = useState<reviewFixedProps[]>([]);
    const [acceptedReviews, setAcceptedReviews] = useState<reviewFixedProps[]>([]);
    const [rejectedReviews, setRejectedReviews] = useState<reviewFixedProps[]>([]);
    const [wasFetched, setWasFetched] = useState(false);
    const [disableButtons, setDisableButtons] = useState(false);


    const handleReviewStatusChange = async (status: string, id: number) => {
        setDisableButtons(true);

        await manageReviewStatus({ action: status, id: id.toString() });

        setWasFetched(false);
        setDisableButtons(false);
    }

    const reviewsStatus = {
        pending: {
            status: "pending",
            actions: [
                {
                    value: "accept",
                    action: (value: any) => { handleReviewStatusChange("accepted", value) }
                },
                {
                    value: "reject",
                    action: (value: any) => { handleReviewStatusChange("rejected", value) }
                }
            ]
        },
        accepted: {
            status: "accepted",
            actions: [
                {
                    value: "reject",
                    action: (value: any) => { handleReviewStatusChange("rejected", value); }
                },
            ]
        },
        rejected: {
            status: "rejected",
            actions: [
                {
                    value: "accept",
                    action: (value: any) => { handleReviewStatusChange("accepted", value) }
                },
                {
                    value: "delete",
                    action: (value: any) => { handleReviewStatusChange("delete", value) }
                }
            ]
        }
    };

    const getAllReviews = useCallback(async () => {
        const request = await getReviewsToManage();
        const allReviews = request.map((review: reviewProps): reviewFixedProps => {
            return {
                id: review.idReview,
                qualification: review.qualification,
                content: review.review,
                status: review.review_status,
                user: review.user_name,
            }
        });
        setPendingReviews(allReviews.filter(
            (review: reviewFixedProps) => review.status === reviewsStatus.pending.status
        ));
        setAcceptedReviews(allReviews.filter(
            (review: reviewFixedProps) => review.status === reviewsStatus.accepted.status
        ));
        setRejectedReviews(allReviews.filter(
            (review: reviewFixedProps) => review.status === reviewsStatus.rejected.status
        ));
        setWasFetched(true);
    }, []);

    useEffect(() => {
        if (!wasFetched) {
            getAllReviews();
        }
    }, [wasFetched]);

    return <section className='reviews-management-container'>
        <FadeInSection fadeInX>
            <section className='review-management-status-section' >
                <h3 className='title2'>Pending reviews</h3>
                <ReviewsManagementTable reviews={pendingReviews}
                    actions={reviewsStatus.pending.actions as []}
                    disabled={disableButtons}
                    loading={!wasFetched} />
            </section>
        </FadeInSection>
        <FadeInSection fadeInX>
            <section className='review-management-status-section' >
                <h3 className='title2'>Accepted reviews</h3>
                <ReviewsManagementTable reviews={acceptedReviews}
                    actions={reviewsStatus.accepted.actions as []}
                    disabled={disableButtons}
                    loading={!wasFetched} />
            </section>
        </FadeInSection>
        <FadeInSection fadeInX>
            <section className='review-management-status-section' >
                <h3 className='title2'>Rejected reviews</h3>
                <ReviewsManagementTable reviews={rejectedReviews}
                    actions={reviewsStatus.rejected.actions as []}
                    disabled={disableButtons}
                    loading={!wasFetched} />
            </section>
        </FadeInSection>
    </section>
}

export default ReviewsManagement;