import { createContext } from "react";

const defaultValues = {
    contactUsLogo: "",
    email: "",
    footerSlogan: "",
    homeYoutubeURL: "",
    mapURL: "",
    navbarLogo: "",
    operatingHours: [],
    phoneNumber: "",
    talkWithUs_logo: "",
    wp_number: "",
    youtubeChannelURL: "",
    homeYoutubeVideoUrl: "",
    wellcomeMessage: "",
    contactMainImage: "",
    aboutMainImage: "",
    serviceMainImage: "",
    galleryMainImage: "",
}

export const GeneralInfoContext = createContext(defaultValues);