import { Outlet, useNavigate } from 'react-router-dom';
import Footer from 'components/Footer';
import Header from 'components/Header';
import { useEffect } from 'react';
import { Hub } from 'aws-amplify/utils';

const Layout = (): JSX.Element => {

  const navigate = useNavigate();
  
  useEffect(() => {
    Hub.listen("auth", (data) => {
      if (data.payload.event === "signedOut") {
        navigate("/login");
      }
    });
  }, []);
  
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
}

export default Layout;