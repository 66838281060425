import 'bootstrap/dist/css/bootstrap.min.css'
import Carousel from 'components/Carousel';
import './style.css'

import FadeInSection from 'containers/FadeInSection';
import LinkIcon from 'components/LinkIcon';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { useCallback, useContext, useEffect, useState } from 'react';
import { getServicesInfo } from 'services';
import { GeneralInfoContext } from 'context';

const Services = (): JSX.Element => {
    const [wasFetched, setWasFetched] = useState(false);
    const [services, setServices] = useState([]);
    const {serviceMainImage, wp_number} = useContext(GeneralInfoContext);
    const getAppInfo = useCallback(async () => {
        const request = await getServicesInfo();
        setServices(request);
        setWasFetched(true)
    }, []);
    useEffect(() => {
        if (!wasFetched) {
            getAppInfo();
        }
    }, []);
    const iconStyles = { color: "#002B36", fontSize: "100px" };
    const linkStyles = {
        border: "2px solid #002B36",
        borderRadius: "8px",
        padding: "10px",
        textDecoration: "none",
        color: "#002B36"
    }
    const backgroundImageStyle = {
        backgroundImage: `url("${serviceMainImage}")`,
      }
    return (
        <div className="services-container">
            <FadeInSection>
                <div className='background-image' style={{...backgroundImageStyle}}>
                    <div className="overlay"></div>

                    <h1 className="white-text">Services</h1>
                </div>
            </FadeInSection>
            {
                services.map((service: { service_name: string; service_page_description: string; service_page_images: [] }, index) => {
                    
                    const serviceImages = service.service_page_images.map((image, index)=>{
                        return {
                            imageUrl: image,
                            imageAlt: `${service.service_name}${index}`
                        }
                    });

                    return (
                        <FadeInSection fadeInX key={index}>
                            <div className="full-column">
                                <div className="service-container">
                                    <h2 className='title2'>{service.service_name}</h2>
                                    <p className='common_text' style={{ textAlign: "center" }}>{service.service_page_description}</p>
                                    <Carousel images={serviceImages} />
                                </div>
                            </div>
                        </FadeInSection>
                    )
                })
            }
            <FadeInSection>
                <div className='full-colmn'>
                    <LinkIcon iconRedirectUrl={`https://wa.me/${wp_number}`}
                        iconStyles={iconStyles}
                        linkStyles={linkStyles}
                        iconLabel={''}
                        iconLabel2={'Request a Quote'}
                        customIcon={faWhatsapp}
                    />
                </div>
            </FadeInSection>
        </div>
    );
}

export default Services;