import { ChangeEvent, useState } from "react";
import '../../../PagesContentManagement/styles.css';
import FadeInSection from "containers/FadeInSection";
import { uploadFiles } from "services";

type NewServiceOptionProps = {
    newServiceName: string;
    newServiceHomePageDescription: string;
    newServicePageDescription: string;
    newservice_images: any[];
    newgallery_images: any[];
    hasChangesToSave: boolean;
    sendingData: boolean;
    onCancel: () => void;
    handleChange: (property: string, value: any, index?: number) => void;
    handleSubmit: (data: object) => void;
}

const NewServiceOption = (props: NewServiceOptionProps) => {
    const {
        newServiceName,
        newServiceHomePageDescription,
        newServicePageDescription,
        newservice_images,
        newgallery_images,
        hasChangesToSave,
        sendingData,
        onCancel,
        handleChange,
        handleSubmit,
    } = props;

    const [newServiceImage, setnewServiceImage] = useState<any | null>(null);
    const [newGalleryImage, setnewGalleryImage] = useState<any | null>(null);

    const handleFileChange = (property: string, event: any) => {
        const file = event.target.files[0];
        switch (property) {
            case 'service_page_images': setnewServiceImage(file); break;
            case 'gallery_page_images': setnewGalleryImage(file); break;
        }
        handleChange('hasChangesToSave', true);
    }

    const handleServInfoChange = (property: string, value: string | [] | File, index: number = 0, oper: string = "none") => {
        handleChange('hasChangesToSave', true);

        const addingNewImage = (data: any[], newValue: any, elementId: string) => {

            let newImages: string[] = [...data];
            if (oper === "add") {
                if (newValue) {
                    newImages = [...data, newValue];
                    (document.getElementById(elementId) as HTMLInputElement)!.value = '';
                }
            } else if (oper === "remove") {
                newImages = [...data];
                newImages.splice(index, 1);
            }
            handleChange(property, [...newImages]);
        }

        switch (property) {
            case "newservice_images": {
                addingNewImage(newservice_images, newServiceImage, 'newServiceImage');
                setnewServiceImage(null);
            } break;
            case "newgallery_images": {
                addingNewImage(newgallery_images, newGalleryImage, 'newGalleryImage');
                setnewGalleryImage(null);
            } break;
            case "selectedImg": handleChange('selectedImg', URL.createObjectURL(value as File)); break;
            default: handleChange(property, value); break;
        }

    }
    const onChange = (event: ChangeEvent<HTMLInputElement>, property: string, index: number = 0, oper: string = "none") => {
        handleServInfoChange(property, event.target.value, index, oper)
    }

    const onSubmit = async (event: any) => {
        event.preventDefault();
        handleChange('sendingData', true);
        const objToSubmit = {
            data: {
                add: {
                    service: {
                        service_name: newServiceName,
                        home_service_description: newServiceHomePageDescription,
                        service_page_description: newServicePageDescription,
                        service_page_images: newservice_images.map((image)=>`serv_srpg_img/${image.name}`),
                        gallery_page_images: newgallery_images.map((image)=>`serv_glpg_img/${image.name}`),
                    }
                }
            }
        };
        const filesToUpload = []
        for (let i=0; i< newservice_images.length; i++) {
            filesToUpload.push({ file: newservice_images[i], path: 'serv_srpg_img'});
        }
        for (let i=0; i< newgallery_images.length; i++) {
            filesToUpload.push({ file: newgallery_images[i], path: 'serv_glpg_img'});
        }
        uploadFiles(filesToUpload);
        handleSubmit(objToSubmit);
        onCancel()
    }

    return <>
        <form onSubmit={onSubmit}>
            <FadeInSection>
                <div className="add-image-panel">
                    <h3 className='title2 gen-opt' style={{ width: "100%", textAlign: "center" }}>Add new service </h3>
                    <div className='option-to-edit'>
                        <h3 className='title2 gen-opt'>Service name: </h3>
                        <input required disabled={sendingData} type="text" value={newServiceName} placeholder="Service name to display" onChange={(e) => onChange(e, "newServiceName")} />
                    </div>
                    <div className='option-to-edit' style={{ flexDirection: "row" }}>
                        <h3 className='title2 gen-opt'>Home page description: </h3>
                        <input required disabled={sendingData} type="text" value={newServiceHomePageDescription} placeholder="Description used in home page" onChange={(e) => onChange(e, "newServiceHomePageDescription")} />
                    </div>
                    <div className='option-to-edit'>
                        <h3 className='title2 gen-opt'>Service page description: </h3>
                        <input required disabled={sendingData} type="text" value={newServicePageDescription} placeholder="Description used in services page" onChange={(e) => onChange(e, "newServicePageDescription")} />
                    </div>

                    <div className='option-to-edit'>
                        <h3 className='title2 gen-opt'>Service page images: </h3>

                        <ol style={{ flexDirection: "column", alignItems: "flex-start" }}>
                            <li style={{ listStyle: "none", marginTop: "15px", marginBottom: "15px", display: "flex", gap: "5px", alignItems: "flex-start" }}>
                                <button
                                    disabled={
                                        newServiceImage === null || (document.getElementById('newServiceImage') as HTMLInputElement)!.value === ''
                                    }
                                    className="custom-button"
                                    type="button"
                                    aria-label="add new"
                                    onClick={() => handleServInfoChange('newservice_images', '', -1, 'add')}>
                                    Add image
                                </button>
                                <input disabled={sendingData} accept="image/png, image/jpeg, image/jpg" type="file" onChange={(e) => handleFileChange('service_page_images', e)} id={'newServiceImage'} />
                            </li>
                            {
                                newservice_images.map((serviceImage: any, index) => {
                                    const currImageName = serviceImage.name;
                                    return <li key={index} style={{ listStyle: "none", marginTop: "5px", display: "flex", gap: "5px", alignItems: "flex-start" }}>
                                        <button onClick={() => handleServInfoChange("selectedImg", serviceImage)} type="button" className="custom-button">See new service image</button>
                                        <button className="custom-button" type="button" aria-label="remove" onClick={() => handleServInfoChange('newservice_images', '', index, 'remove')}> X </button>
                                        <h3 className='title2 gen-opt' style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{currImageName}</h3>
                                    </li>
                                })
                            }
                        </ol>
                    </div>

                    <div className='option-to-edit'>
                        <h3 className='title2 gen-opt'>Gallery page images: </h3>

                        <ol style={{ flexDirection: "column", alignItems: "flex-start" }}>
                            <li style={{ listStyle: "none", marginTop: "15px", marginBottom: "15px", display: "flex", gap: "5px", alignItems: "flex-start" }}>
                                <button
                                    disabled={
                                        newGalleryImage === null || (document.getElementById('newGalleryImage') as HTMLInputElement)!.value === ''
                                    }
                                    className="custom-button"
                                    type="button"
                                    aria-label="add new"
                                    onClick={() => handleServInfoChange('newgallery_images', '', -1, 'add')} >
                                    Add image
                                </button>
                                <input disabled={sendingData} accept="image/png, image/jpeg, image/jpg" type="file" onChange={(e) => handleFileChange('gallery_page_images', e)} id={'newGalleryImage'} />
                            </li>
                            {
                                newgallery_images.map((serviceImage: any, index) => {
                                    const currImageName = serviceImage.name;
                                    return <li key={index} style={{ listStyle: "none", marginTop: "5px", display: "flex", gap: "5px", alignItems: "flex-start" }}>
                                        <button onClick={() => handleServInfoChange("selectedImg", serviceImage)} type="button" className="custom-button">See new gallery image</button>
                                        <button className="custom-button" type="button" aria-label="remove" onClick={() => handleServInfoChange('newgallery_images', '', index, 'remove')}> X </button>
                                        <h3 className='title2 gen-opt' style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{currImageName}</h3>
                                    </li>
                                })
                            }
                        </ol>
                    </div>


                    <div className='option-to-edit'>

                    </div>
                </div>


                <div style={{ display: "flex", gap: "5px", paddingTop: "5px" }}>
                    <button disabled={!hasChangesToSave || sendingData} className='custom-button' type='submit'>Save new service</button>
                    <button className='custom-button' type='submit' onClick={onCancel}>Cancel</button>
                </div>
            </FadeInSection>
        </form>
    </>
}

export default NewServiceOption;