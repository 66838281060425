import { Button, Heading, withAuthenticator } from '@aws-amplify/ui-react'; import { getCurrentUser, type AuthUser } from "aws-amplify/auth";
import { type UseAuthenticator } from "@aws-amplify/ui-react-core";
import '@aws-amplify/ui-react/styles.css';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Hub } from 'aws-amplify/utils';

export type AuthProps = {
  signOut?: UseAuthenticator["signOut"];
  user?: AuthUser;
};

const Login = ({ signOut, user }: AuthProps) => {
  const navigate = useNavigate();

  const IsLogged = useCallback(async () => {
    try {
      const currUser = await getCurrentUser()
      if (currUser !== undefined) {
        return true;
      }
      return false;
    } catch {
      return false;
    }
  }, []);

  const checkRedirect = async () => {
    if (await IsLogged()) {
      navigate("/management");
    }
  }

  useEffect(() => {

    checkRedirect();

    Hub.listen("auth", (data) => {
      if (data.payload.event === "signedIn") {
        navigate("/management");
      }
    });
  }, []);

  return <div style={
    {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      width: "100%",
      padding: "20px",
      flexDirection: "column",
      gap: "20px"
    }
  }>
    <Heading level={4}>You're not supposed to be here... an unexpected error occurred, log out and request support</Heading>
    <Button onClick={signOut}><Heading level={4}>Sign out</Heading></Button>
  </div>
}

export default withAuthenticator(Login, { hideSignUp: true });