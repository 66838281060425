import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './style.css';
import { GeneralInfoContext } from 'context';
import { getCurrentUser, signOut } from 'aws-amplify/auth';


const Navbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [userLogged, setUserLogged] = useState(false);
  const { navbarLogo } = useContext(GeneralInfoContext)
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const IsLogged = useCallback(async () => {
    try {
      const currUser = await getCurrentUser()
      if (currUser !== undefined) {
        return true;
      }
      return false;
    } catch {
      return false;
    }
  }, []);

  const checkIfLogged = async () => {
    if (await IsLogged()) {
      setUserLogged(true);
    }
  }

  useEffect(() => {
    checkIfLogged();
  }, []);

  const handleSignOut = useCallback(async () => {
    try {
      await signOut();
    } catch (error) {
      console.log('error signing out ');
    }
  }, []);

  const admOptions = () => {
    if (userLogged) {
      return (<>
        <Link to="/management" onClick={() => setMobileMenuOpen(false)}>
          Management
        </Link>
        <Link to="/login" onClick={() => { 
          setMobileMenuOpen(false); 
          handleSignOut(); 
          }}>
          Logout
        </Link>
      </>)
    }
    return null;
  }

  return (
    <div className="navbar">
      <div className="logo">
        <Link to="/">
          <img src={navbarLogo} alt='carlos ulloa logo to home' className='img-logo' />
        </Link>
      </div>
      <div className={`nav-options ${mobileMenuOpen ? 'open' : ''}`}>
        <Link to="/" onClick={() => setMobileMenuOpen(false)}>
          Home
        </Link>
        <Link to="/services" onClick={() => setMobileMenuOpen(false)}>
          Services
        </Link>
        <Link to="/gallery" onClick={() => setMobileMenuOpen(false)}>
          Gallery
        </Link>
        <Link to="/contact" onClick={() => setMobileMenuOpen(false)}>
          Contact Us
        </Link>
        <Link to="/about" onClick={() => setMobileMenuOpen(false)}>
          About Us
        </Link>
        {admOptions()}
      </div>
      <div className={`hamburger ${mobileMenuOpen ? 'open' : ''}`} onClick={toggleMobileMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
    </div>
  );
};

export default Navbar;