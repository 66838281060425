import './styles.css'
import HomePageCarousel from 'components/HomePageCarousel';
import YoutubeEmbed from 'components/YoutubeComponent';
import FadeInSection from 'containers/FadeInSection';
import { faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';
import LinkIcon from 'components/LinkIcon';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import Ratings from 'containers/Ratings';
import { useCallback, useContext, useEffect, useState } from 'react';
import { GeneralInfoContext } from 'context';
import { getHomeInfo } from 'services';
const Home = (): JSX.Element => {
    const [wasFetched, setWasFetched] = useState(false);
    const { homeYoutubeVideoUrl, talkWithUs_logo, wp_number } = useContext(GeneralInfoContext);
    const [homeInfoSectionTitle, setHomeInfoSectionTitle] = useState('');
    const [homeInfoSectionDescription, setHomeInfoSectionDescription] = useState('');
    const [homeTransparentBackground, setHomeTransparentBackground] = useState('');
    const [homeCarouselImages, setHomeCarouselImages] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [services, setServices] = useState([]);
    const getAppInfo = useCallback(async () => {
        const request = await getHomeInfo();
        setHomeInfoSectionTitle(request.home_info_section_title);
        setHomeInfoSectionDescription(request.home_info_section_description);
        setHomeCarouselImages(request.home_carousel_images);
        setHomeTransparentBackground(request.home_transparent_background);
        setReviews(request.review);
        setServices(request.service);
        setWasFetched(true)
    }, []);
    useEffect(() => {
        if (!wasFetched) {
            getAppInfo();
        }
    }, []);

    const iconStyles = { color: "#FFFFFF", fontSize: "100px" };

    const fixedBackground = {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPositionY: 'center',
        backgroundImage: `url("${homeTransparentBackground}")`,
    }
    return (
        <>
            <div className="fixed-background" style={{ ...fixedBackground }} />
            <div className="home-container">
                <div style={{ backgroundColor: "#002B36" }}>
                    <FadeInSection>
                        <HomePageCarousel images={homeCarouselImages as []} />
                    </FadeInSection>
                </div>
                <div style={{ backgroundColor: "#002B36" }}>
                    <FadeInSection fadeInX>
                        <div className="two-column" style={{ backgroundColor: "#002B36", color: "#FFFFFF" }}>
                            <div className="left-column">
                                <LinkIcon iconRedirectUrl={'/services'}
                                    iconStyles={iconStyles}
                                    iconLabel={'Services'}
                                    iconLabel2={''}
                                    customIcon={faScrewdriverWrench}
                                />
                            </div>
                            <div className="right-column" style={{ color: "#FFFFFF" }}>
                                <LinkIcon iconRedirectUrl={`https://wa.me/${wp_number}`}
                                    iconStyles={iconStyles}
                                    iconLabel={'Request a Quote'}
                                    iconLabel2={''}
                                    customIcon={faWhatsapp}
                                />
                            </div>
                        </div>
                    </FadeInSection>
                </div>
                <div className="transparent-section" style={{ width: "100%" }}>
                    <FadeInSection fadeInX centered>
                        <div className="full-column" style={{ textAlign: "center", minHeight: "450px" }}>
                            <h2 className='title'>{homeInfoSectionTitle}</h2>
                            <p className='common_text'>
                                {homeInfoSectionDescription}
                            </p>
                        </div>
                    </FadeInSection>
                </div>
                <div style={{ backgroundColor: "#E0EFD8" }}>
                    <FadeInSection>
                        <div className="two-column  darker-background vertical-padding">
                            <div className="right-padding left-column services">
                                {
                                    services.map(({ service_name, home_service_description }, index) => {
                                        return <div key={index}>
                                            <div className='two-column  service_description'>
                                                <h3 className='title2'>{service_name}</h3>
                                                <p className='service_description common_text'>{home_service_description}</p>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                            <div className="right-column">
                                <div className="icon-container">
                                    <a href='/contact' aria-label='redirect to whatsapp'>
                                        <img src={talkWithUs_logo} alt="carlos logo" style={{ width: "350px", objectFit: "cover" }} />
                                        <h2 className='title2'>Talk With Us</h2>
                                    </a>
                                </div>
                                <YoutubeEmbed videoURL={homeYoutubeVideoUrl} />
                            </div>
                        </div>
                    </FadeInSection>
                </div>
                <div style={{ backgroundColor: "#E0EFD8" }}>
                    <FadeInSection>
                        <Ratings reviews={reviews} />
                    </FadeInSection>
                </div>
            </div>
        </>
    );
}

export default Home;