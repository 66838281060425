import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

import './style.css'

type LinkIconPropTypes = {
    iconRedirectUrl: string;
    iconStyles: object;
    linkStyles?: object;
    iconLabel: string;
    iconLabel2: string;
    customIcon: IconProp;
}

const LinkIcon = (props: LinkIconPropTypes) => {

    
    const [isHover, setIsHover] = useState(false);

    const handleMouseOver=()=>{
        setIsHover(true);
    }
    const handleMouseOut=()=>{
        setIsHover(false);
    }
    return (
        <div className="icon-container">
            <a href={props.iconRedirectUrl} target="_blank" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} style={props.linkStyles} >
                <FontAwesomeIcon icon={props.customIcon} bounce={isHover} style={props.iconStyles} />
                <h2 className='title'>{props.iconLabel}</h2>
                <h2 className='title2'>{props.iconLabel2}</h2>
            </a>
        </div>
    )
}

export default LinkIcon;