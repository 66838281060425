import RatingCard from "components/RatingCard";
import Slider from "react-slick";
import "./styles.css"
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import Modal from "components/Modal";
import FadeInSection from "containers/FadeInSection";
import { sendReview } from "services";

type ReviewFormTypes = {
    closeModal: () => void;
    rating: number;
    handleRatingChange: (value: number) => void;
}

const ReviewForm = (props: ReviewFormTypes) => {
    const [name, setName] = useState("");
    const [review, setReview] = useState("");
    const [operationResult, setOperationResult] = useState("");
    const [loading, setLoading] = useState(false);
    const { closeModal, rating, handleRatingChange } = props

    const initValues = () => {
        setLoading(false);
        setName("");
        setReview("");
        handleRatingChange(5);
    }

    const handleChangeName = (event: any) => {
        setName(event.target.value)
    }

    const handleReviewChange = (event: any) => {
        setReview(event.target.value)
    }
    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        setLoading(true);
        setOperationResult('');
        const bodyParameters = { user_name:name, review: review , qualification: `${rating}` };
        const answer = await sendReview(bodyParameters);
        initValues();
        setOperationResult(answer);
    }

    const handleClose = () => {
        closeModal();
        initValues();
        setOperationResult("");
    }

    return <FadeInSection>
        <div className="add-review-container content">
            <form onSubmit={handleSubmit}>
                <FadeInSection fadeInX>
                    <RatingCard rating={rating}
                        title={"Give us your feedback!"}
                        description={"We will be happy to know your opinion"}
                        isCard={true}
                        handleRatingChange={handleRatingChange} />
                </FadeInSection>
                <FadeInSection>
                    <div style={{ display: "flex", flexDirection: "column", gap: "10px", marginBottom: "10px" }}>
                        <input disabled={loading} style={{ backgroundColor: "#E0EFD8", borderRadius: "8px" }} type="text" value={name} onChange={handleChangeName} placeholder="Your Name!" />
                        <textarea disabled={loading} style={{ backgroundColor: "#E0EFD8", borderRadius: "8px" }} maxLength={300} name="review" rows={10} cols={50} value={review} onChange={handleReviewChange} placeholder="Write your review here!" />
                    </div>
                </FadeInSection>
                <FadeInSection fadeInX>
                    <div style={{ display: "flex", gap: "10px", justifyContent: "center" }}>
                        <button disabled={loading} className="custom-button" type="submit" style={{ maxWidth: "fit-content" }}>Send Review</button>
                        <button onClick={handleClose} disabled={loading} className="custom-button cancel-button" type="button" style={{ maxWidth: "fit-content" }}>Cancel</button>
                    </div>
                </FadeInSection>
                <div>
                    <p className="common-text" style={{color: "#002B36 !important", textAlign: "center", marginTop: "1rem", marginBottom: "0"}}>
                        {operationResult}
                    </p>
                </div>
            </form>
        </div>
    </FadeInSection>
}

function CustomArrow(props: any) {
    const { customClassName, icon, onClick } = props;
    const [isHover, setIsHover] = useState(false);
    const handleMouseOver = () => {
        setIsHover(true);
    }
    const handleMouseOut = () => {
        setIsHover(false);
    }

    return (
        <div
            className={`custom-arrow ${customClassName}`}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onClick={onClick}
            style={{ border: "3px solid #002B36", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "50%" }}
        >
            <FontAwesomeIcon icon={icon} beatFade={isHover} style={{ color: "#002B36", fontSize: "25px" }} />
        </div>
    );
}
type ReviewType = {
    user_name: string
    review: string,
    qualification: number
}
type RatingsPropTypes = {
    reviews: ReviewType[];
}
const Ratings = (props: RatingsPropTypes) => {
    const { reviews } = props;
    const [rating, setRating] = useState(5);
    const [isModalOpen, setOpenModal] = useState(false);

    const qtyReviews = reviews.length;
    const accReviewsValue = reviews.reduce(
        (accumulator, currentValue) => accumulator + currentValue.qualification,
        0,
    );
    const generalReviewValue = qtyReviews > 0 ? accReviewsValue / qtyReviews : 0;
    const handleRatingChange = (selectedRating: React.SetStateAction<number>) => {
        setRating(selectedRating);
    };

    const closeModal = () => {
        setOpenModal(false);
        setRating(5);
    }

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
        arrows: true,
        dots: false,
        cssEase: "linear",
        nextArrow: <CustomArrow icon={faChevronRight} customClassName="custom-next" />,
        prevArrow: <CustomArrow icon={faChevronLeft} customClassName="custom-prev" />,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],

    };

    return (
        <div className="ratings-card">
            <div style={{ justifyContent: "center", display: "flex", maxWidth: "none" }}>
                <RatingCard rating={generalReviewValue} title={'Our Customers Opinions'} description={''} isCard={false} />
            </div>
            {
                <div style={{ justifyContent: "center", display: "flex", maxWidth: "none", marginTop: "10px" }}>
                    <button onClick={() => setOpenModal(true)} className="custom-button" type="button" style={{ maxWidth: "fit-content", marginBottom: "10px", fontSize: "1.5rem" }}>Write a review</button>
                </div>
            }
            <Slider {...settings}>
                {reviews.map((rating: any, index: React.Key) => {
                    return (
                        <div key={index}>
                            <RatingCard rating={rating.qualification}
                                title={rating.user_name}
                                description={rating.review}
                                isCard={rating.isCard} />
                        </div>
                    );
                })}
            </Slider>
            {isModalOpen && <Modal ><ReviewForm closeModal={closeModal} rating={rating} handleRatingChange={handleRatingChange} /></Modal>}
        </div>
    )
}

export default Ratings;

