import FadeInSection from 'containers/FadeInSection'
import '../ContactUs/style.css'
import { useContext, useState } from 'react'
import { GeneralInfoContext } from 'context'
import { contactAdmin } from 'services'

const ContactUs = (): JSX.Element => {
  const {contactUsLogo, contactMainImage, phoneNumber, email, mapURL} = useContext(GeneralInfoContext);
  const [userName, setUserName]=useState<string>("");
  const [userEmail, setUserEmail]=useState<string>("");
  const [userPhone, setUserPhone]=useState<string>("");
  const [message, setMessage]=useState<string>("");
  const [contactMessage, setContactMessage]=useState<string>("");
  
  let overview: string = "Thank you for considering Carlos Ulloa, LLC. Construction as your trusted partner in creating the home of your dreams. Whether you have questions, want to discuss your upcoming project, or simply wish to say hello, we're here to assist you. Your feedback and inquiries are important to us."
  let phone: string = `Feel free to call us at ${phoneNumber} during our business hours, and our friendly team will be ready to assist you.`;
  let email_message: string = `You can also drop us an email at ${email}, and we will respond promptly to address your inquiries or concerns.`;
  const backgroundImageStyle = {
    backgroundImage: `url("${contactMainImage}")`,
  }
  const handleSubmit = async (e: { preventDefault: () => void })=>{
    e.preventDefault();
    setContactMessage("")
    const bodyParameters = {name: userName,phone: userPhone,email: userEmail,message: message};
    const answer=await contactAdmin(bodyParameters);
    setUserName("");
    setUserEmail("");
    setUserPhone("");
    setMessage("");
    setContactMessage(answer.message);
  };
  return (
    <div className="contact-container">
      <FadeInSection>
        <div className='backgroundImage' style={{...backgroundImageStyle}}>
          <div className="overlay"></div>
          <h1 className='whiteText'>Contact Us</h1>
        </div>
      </FadeInSection>
      <div className='twoColumn'>
        <div className="leftColumn">

          <FadeInSection fadeInX>
            <p className='common_text'>{overview}</p>
            <form className='vertical-form' onSubmit={handleSubmit}>
              <label className="name">Name</label>
              <input required className="imput_area" onChange={(e)=>setUserName(e.target.value)} value={userName} type="text" placeholder='Your name' />
              <label className="phone">Phone Number</label>
              <input required className="imput_area" onChange={(e)=>setUserPhone(e.target.value)} value={userPhone} type="tel" placeholder="xxx-xxx-xxxx" />
              <label className="email">Email</label>
              <input required className="imput_area" onChange={(e)=>setUserEmail(e.target.value)} value={userEmail} type="email" placeholder="youremail@email.com" />
              <label className="message">Message</label>
              <textarea required className="imput_area" onChange={(e)=>setMessage(e.target.value)} value={message} id="message" cols={50} rows={10} placeholder="Leave us a message. We will call you back!"></textarea>
              <button className="custom-button" type="submit">Send</button>
              <div>
                    <p className="common-text" style={{color: "#002B36 !important", textAlign: "center", marginBottom: "0"}}>
                        {contactMessage}
                    </p>
                </div>
            </form>
          </FadeInSection>
        </div>
        <div className="rightColumn">

          <FadeInSection fadeInX>
            <h2 className='title2'>Phone</h2>
            <p className='common_text'>{phone}</p>
            <h2 className='title2'>Email</h2>
            <p className='common_text'>{email_message}</p>
            <a href={mapURL} target='_blank' style={{textDecoration: "none", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
            <img src={contactUsLogo} id="logo" alt="carlos ulloa llc map link logo" style={{ width: "640px", objectFit: "cover" }} />
            <h2 className='title2'>Click here to find us!</h2>
            </a>
          </FadeInSection>
        </div>
      </div>
    </div>
  )
}
export default ContactUs;