import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './routes/App';
import GeneralInfo from 'containers/GeneralInfo';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports.js';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: awsExports.Auth.Cognito.userPoolClientId,
      userPoolId: awsExports.Auth.Cognito.userPoolId,
      loginWith: {
        email: awsExports.Auth.Cognito.loginWith.email,
      },
    }
  }
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <GeneralInfo>
      <App />
    </GeneralInfo>
  </React.StrictMode>
);
