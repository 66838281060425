import React, { useEffect, useState } from "react";
import { useInView } from 'react-intersection-observer';
import './style.css'

type FadeSectionPropTypes = {
  children: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal;
  fadeInX?: boolean;
  centered?: boolean;
  alwaysFade?:boolean;
}

const FadeInSection = (props: FadeSectionPropTypes) => {
  const [wasDisplayed, setWasDisplayed] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const fadeOrientation = props.fadeInX ? 'x-fade' : 'y-fade';
  const xDirection = props.centered ? 'fade-centered' : '';
  const compStyles = `${!wasDisplayed ? `fade-in-section ${fadeOrientation} ${inView ? "is-visible" : ""}` : ""} ${xDirection}`
  useEffect(() => {
    if (!props.alwaysFade){
      if (inView && !wasDisplayed) {
        setTimeout(() => setWasDisplayed(true), 1200)
      }
    }
  }, [inView]);

  return (
    <div
      ref={ref}
      className={compStyles}
    >
      {props.children}
    </div>
  );
};

export default FadeInSection;
