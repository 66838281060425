import ServiceOption, { ServiceType } from "./components/ServiceOption";

type UpdateServicesOptionProps = {
    activeIndex: number;
    updservice_images: any[];
    updgallery_images: any[];
    serv_images_to_remove: string[];
    gall_images_to_remove: string[];
    services: ServiceType[];
    handleChange: (property: string, value: any, index?: number) => void;
}
const UpdateServicesOption = (props: UpdateServicesOptionProps) => {
    const {
        activeIndex,
        updservice_images,
        updgallery_images,
        serv_images_to_remove,
        gall_images_to_remove,
        services,
        handleChange
    } = props;
    const handleServInfoChange = (property: string, value: any, imageIndex: number = -1, oper: string = "none", isNew: boolean = false) => {
        handleChange('hasChangesToSave', true);
        let serviceToModify = services[activeIndex];


        const imageBehavior = (data: any[]) => {

            if (oper === "add") {
                if (value) {
                    const newImages = property === "service_page_images" ?
                        [...updservice_images] : [...updgallery_images];
                    newImages[activeIndex].push(value)

                    handleChange(property === "service_page_images" ?
                        'updservice_images' : 'updgallery_images', [...newImages]);

                    (document.getElementById(`${property}-${services[activeIndex].idService}`) as HTMLInputElement)!.value = '';
                }
            } else if (oper === "remove") {
                if (!isNew) {
                    const newImages: string[] = [...data];
                    // Remove item first
                    const removedImage = newImages.splice(imageIndex, 1);
                    // modify the gallery/service images array
                    const newDataInfo = property === "service_page_images" ?
                        [...services, services[activeIndex].service_page_images = [...newImages]] :
                        [...services, services[activeIndex].gallery_page_images = [...newImages]];
                    // adding to removed items
                    const newRemovedImages = property === "service_page_images" ?
                        [...serv_images_to_remove] : [...gall_images_to_remove];

                    newRemovedImages.push(removedImage[0]);

                    handleChange(property === "service_page_images" ?
                        'serv_images_to_remove' : 'gall_images_to_remove', [...newRemovedImages]);
                    handleChange("services", [...newDataInfo]);
                } else {

                    const newImages = property === "service_page_images" ?
                        [...updservice_images] : [...updgallery_images];

                    newImages[activeIndex].splice(imageIndex,1);
                    
                    handleChange(property === "service_page_images" ?
                        'updservice_images' : 'updgallery_images', [...newImages]);
                }
            }


        }

        switch (property) {
            case "service_name": serviceToModify = { ...serviceToModify, service_name: value }; break;
            case "home_service_description": serviceToModify = { ...serviceToModify, home_service_description: value }; break;
            case "service_page_description": serviceToModify = { ...serviceToModify, service_page_description: value }; break;
            case "service_page_images": {
                imageBehavior(services[activeIndex].service_page_images);
            } break;
            case "gallery_page_images": {
                imageBehavior(services[activeIndex].gallery_page_images);
            } break;
            default: handleChange(property, value);
        }

        if (!["selectedImg", "serviceToDelete"].find(value => value === property)) {
            const newServicesData = [...services];
            newServicesData[activeIndex] = serviceToModify;
            handleChange("services", [...newServicesData]);
        }
    }


    return <>
        {
            services.map((service: ServiceType, index) => {
                return activeIndex === index ? (
                    <ServiceOption
                        key={index}
                        service={service}
                        updservice_images={updservice_images[index]}
                        updgallery_images={updgallery_images[index]}
                        sendingData={false}
                        handleChange={handleServInfoChange}
                    />) : (
                    <div className="pages-content-section" style={{ marginTop: "10px", marginBottom: "10px" }} key={index}>

                        <button
                            className="custom-button"
                            type="button"
                            onClick={() => handleChange('activeIndex', index)}>
                            Open {service.service_name} data
                        </button>

                    </div>

                )
            })
        }
    </>
}

export default UpdateServicesOption;