import FadeInSection from 'containers/FadeInSection';
import './styles.css';
import { useCallback, useEffect, useState } from 'react';
import { getContentData, manageData } from 'services';
import GeneralInformationSection from 'containers/GeneralInformationSection';
import CompanyInformationSection from 'containers/CompanyInformationSection';
import ServicesInformationSection from 'containers/ServicesInformationSection';
import Modal from 'components/Modal';
import PagesInformationSection from 'containers/PagesInformationSection';
import { ServiceType } from 'containers/ServicesInformationSection/components/UpdateServicesOption/components/ServiceOption';

type DeletionModalProps = {
    serviceToDelete: ServiceType;
    sendingData: boolean;
    onAccept: () => void;
    onCancel: () => void;
}

const ServiceDeletionModal = (props: DeletionModalProps) => {
    const {
        serviceToDelete,
        sendingData,
        onAccept,
        onCancel
    } = props;
    return <Modal>
        <div style={{ marginTop: "10%", width: "100%", display: "flex", justifyContent: "center" }}>
            <div style={{ padding: "20px", backgroundColor: "#ffffff", width: "80%", display: "flex", flexDirection: "column", textAlign: "center", justifyContent: "center" }}>
                <h3 className='title2'>You are about to delete the service {serviceToDelete.service_name}</h3>
                <p className='common-text'>
                    If you perform this action all the information and images that belongs to the service {serviceToDelete.service_name} will be erased.
                    Are you sure?
                </p>
                <div style={{ display: "flex", gap: "10px", justifyContent: "center" }}>
                    <button style={{ width: "156px", backgroundColor: "#e80712", color: "#ffffff" }} disabled={sendingData} className='custom-button' onClick={onAccept}>Delete service</button>
                    <button style={{ width: "156px" }} disabled={sendingData} className='custom-button' onClick={onCancel}>Cancel</button>

                </div>
            </div>
        </div>
    </Modal>
}


const PagesContentManagement = () => {
    const [wasFetched, setWasFetched] = useState(false);
    // General information section data.
    const [phone_number, setPhone_number] = useState('');
    const [whatsapp, setWhatsapp] = useState('');
    const [email, setEmail] = useState('');
    const [homeYoutubeVideoUrl, setHomeYoutubeVideoUrl] = useState('');
    const [youtubeChannelUrl, setYoutubeChannelUrl] = useState('');
    const [map_url, setMap_url] = useState('');
    const [navbarlogo, setNavbarlogo] = useState('');
    const [talkWithUs_logo, setTalkWithUs_logo] = useState('');
    const [contactus_logo, setContactus_logo] = useState('');
    const [operating_hour, setOperating_hour] = useState<string[]>([]);
    const [footer_slogan, setFooter_slogan] = useState('');
    // company information section data.
    const [mision, setMision] = useState('');
    const [vision, setVision] = useState('');
    const [principalObjective, setPrincipalObjectives] = useState('');
    const [specificObjectives, setSpecificObjectives] = useState<string[]>([]);
    const [welcomeMessage, setWelcomeMessage] = useState('');
    // services information section data
    const [services, setServices] = useState<ServiceType[]>([]);
    // pages information section data.
    const [about_main_image, setabout_main_image] = useState('');
    const [contact_main_image, setcontact_main_image] = useState('');
    const [gallery_main_image, setgallery_main_image] = useState('');
    const [home_carousel_images, sethome_carousel_images] = useState<string[]>([]);
    const [home_info_section_description, sethome_info_section_description] = useState('');
    const [home_info_section_title, sethome_info_section_title] = useState('');
    const [home_transparent_background, sethome_transparent_background] = useState('');
    const [services_main_image, setservices_main_image] = useState('');
    // Services information section data
    const [newServiceName, setserviceName] = useState('');
    const [newServiceHomePageDescription, setnewServiceHomePageDescription] = useState('');
    const [newServicePageDescription, setnewServicePageDescription] = useState('');
    const [newservice_images, setnewservice_images] = useState<any[]>([]);
    const [newgallery_images, setnewgallery_images] = useState<any[]>([]);
    
    const [updservice_images, setupdservice_images] = useState<any[]>([]);
    const [updgallery_images, setupdgallery_images] = useState<any[]>([]);
    const [serv_images_to_remove,setserv_images_to_remove] = useState<string[]>([]);
    const [gall_images_to_remove,setgall_images_to_remove] = useState<string[]>([]);
    const [serviceToDelete, setserviceToDelete] = useState<ServiceType | null>(null);
    const [activeIndex, setactiveIndex] = useState(0);
    // submition data management flags
    const [selectedImg, setSelectedImg] = useState<null | string>(null);
    const [hasChangesToSave, sethasChangesToSave] = useState(false);
    const [sendingData, setSendingData] = useState(false);
    const [activeOption, setActiveOption] = useState(0);

    const stateValueToChange = useCallback((property: string, value: boolean | string | string[] | object | number) => {
        switch (property) {
            case 'phone_number': setPhone_number(value as string); break;
            case 'whatsapp': setWhatsapp(value as string); break;
            case 'email': setEmail(value as string); break;
            case 'homeYoutubeVideoUrl': setHomeYoutubeVideoUrl(value as string); break;
            case 'youtubeChannelUrl': setYoutubeChannelUrl(value as string); break;
            case 'map_url': setMap_url(value as string); break;
            case 'navbarlogo': setNavbarlogo(value as string); break;
            case 'talkWithUs_logo': setTalkWithUs_logo(value as string); break;
            case 'contactus_logo': setContactus_logo(value as string); break;
            case 'operating_hour': setOperating_hour(value as string[]); break;
            case 'footer_slogan': setFooter_slogan(value as string); break;
            case 'mision': setMision(value as string); break;
            case 'vision': setVision(value as string); break;
            case 'principalObjective': setPrincipalObjectives(value as string); break;
            case 'specificObjectives': setSpecificObjectives(value as string[]); break;
            case 'welcomeMessage': setWelcomeMessage(value as string); break;
            case 'about_main_image': setabout_main_image(value as string); break;
            case 'contact_main_image': setcontact_main_image(value as string); break;
            case 'gallery_main_image': setgallery_main_image(value as string); break;
            case 'home_carousel_images': sethome_carousel_images(value as string[]); break;
            case 'home_info_section_description': sethome_info_section_description(value as string); break;
            case 'home_info_section_title': sethome_info_section_title(value as string); break;
            case 'home_transparent_background': sethome_transparent_background(value as string); break;
            case 'services_main_image': setservices_main_image(value as string); break;
            case 'newServiceName': setserviceName(value as string); break;
            case 'newServiceHomePageDescription': setnewServiceHomePageDescription(value as string); break;
            case 'newServicePageDescription': setnewServicePageDescription(value as string); break;
            case 'newservice_images': setnewservice_images(value as any[]); break;
            case 'newgallery_images': setnewgallery_images(value as any[]); break;
            case 'services': setServices(value as ServiceType[]); break;
            case 'serviceToDelete': setserviceToDelete(value as ServiceType | null); break;
            case 'activeIndex' : setactiveIndex(value as number); break;
            case 'updservice_images': setupdservice_images(value as any[]); break;
            case 'updgallery_images': setupdgallery_images(value as any[]); break;
            case 'serv_images_to_remove': setserv_images_to_remove(value as string[]); break;
            case 'gall_images_to_remove': setgall_images_to_remove(value as string[]); break;

            case 'sendingData': setSendingData(value as boolean); break;
            case 'selectedImg': setSelectedImg(value as string); break;
            case 'hasChangesToSave': sethasChangesToSave(value as boolean); break;
        }
    }, []);

    const getApplicationData = useCallback(async () => {
        const request = await getContentData();
        // general system information
        stateValueToChange('phone_number', request.general_info[0].phone_number);
        stateValueToChange('whatsapp', request.general_info[0].whatsapp);
        stateValueToChange('email', request.general_info[0].email);
        stateValueToChange('homeYoutubeVideoUrl', request.general_info[0].homeYoutubeVideoUrl);
        stateValueToChange('youtubeChannelUrl', request.general_info[0].youtubeChannelUrl);
        stateValueToChange('map_url', request.general_info[0].map_url);
        stateValueToChange('navbarlogo', request.general_info[0].navbarlogo);
        stateValueToChange('talkWithUs_logo', request.general_info[0].talkWithUs_logo);
        stateValueToChange('contactus_logo', request.general_info[0].contactus_logo);
        stateValueToChange('operating_hour', request.general_info[0].operating_hour.map((value: string) => {
            return value;
        }));
        stateValueToChange('footer_slogan', request.general_info[0].footer_slogan);
        // Company information
        stateValueToChange('mision', request.company_info[0].mision);
        stateValueToChange('vision', request.company_info[0].vision);
        stateValueToChange('principalObjective', request.company_info[0].principal_objective);
        stateValueToChange('specificObjectives', request.company_info[0].specific_objectives.map((value: string) => {
            return value;
        }));
        stateValueToChange('welcomeMessage', request.company_info[0].welcome_message);
        // Pages information
        stateValueToChange('about_main_image', request.pages_info[0].about_main_image);
        stateValueToChange('contact_main_image', request.pages_info[0].contact_main_image);
        stateValueToChange('gallery_main_image', request.pages_info[0].gallery_main_image);
        stateValueToChange('home_carousel_images', request.pages_info[0].home_carousel_images);
        stateValueToChange('home_info_section_description', request.pages_info[0].home_info_section_description);
        stateValueToChange('home_info_section_title', request.pages_info[0].home_info_section_title);
        stateValueToChange('home_transparent_background', request.pages_info[0].home_transparent_background);
        stateValueToChange('services_main_image', request.pages_info[0].services_main_image);
        // services
        stateValueToChange('services', request.services);
        stateValueToChange('updservice_images', request.services.map(()=>[]));
        stateValueToChange('updgallery_images', request.services.map(()=>[]));

        setWasFetched(true);
    }, []);

    useEffect(() => {
        if (!wasFetched) {
            getApplicationData();
        }
    }, [wasFetched]);

    const handleSubmit = async (submitData: object) => {
        setSendingData(true);
        await manageData(submitData);
        setSendingData(false);
        setWasFetched(false);
        setserviceToDelete(null);
        stateValueToChange('hasChangesToSave', false);
    }

    const informationSections = [
        {
            title: "Services Management",
            element: <ServicesInformationSection 
                activeIndex={activeIndex} 
                newServiceName={newServiceName}
                newServiceHomePageDescription={newServiceHomePageDescription}
                newServicePageDescription={newServicePageDescription}
                newservice_images={newservice_images}
                newgallery_images={newgallery_images}
                updservice_images={updservice_images}
                updgallery_images={updgallery_images}
                serv_images_to_remove={serv_images_to_remove}
                gall_images_to_remove={gall_images_to_remove}
                services={services}
                hasChangesToSave={hasChangesToSave}
                handleChange={stateValueToChange}
                sendingData={sendingData}
                handleSubmit={handleSubmit}/>,
        },
        {
            title: "Pages Content",
            element: <PagesInformationSection
                about_main_image={about_main_image}
                contact_main_image={contact_main_image}
                gallery_main_image={gallery_main_image}
                home_carousel_images={home_carousel_images}
                home_info_section_description={home_info_section_description}
                home_info_section_title={home_info_section_title}
                home_transparent_background={home_transparent_background}
                services_main_image={services_main_image}
                hasChangesToSave={hasChangesToSave}
                handleChange={stateValueToChange}
                sendingData={sendingData}
                handleSubmit={handleSubmit} />,
        },
        {
            title: "Company Information",
            element: <CompanyInformationSection
                mision={mision}
                vision={vision}
                principalObjective={principalObjective}
                specificObjectives={specificObjectives}
                welcomeMessage={welcomeMessage}
                hasChangesToSave={hasChangesToSave}
                handleChange={stateValueToChange}
                sendingData={sendingData}
                handleSubmit={handleSubmit} />,
        },
        {
            title: "General Information",
            element: <GeneralInformationSection
                phone_number={phone_number}
                whatsapp={whatsapp}
                email={email}
                homeYoutubeVideoUrl={homeYoutubeVideoUrl}
                youtubeChannelUrl={youtubeChannelUrl}
                map_url={map_url}
                navbarlogo={navbarlogo}
                talkWithUs_logo={talkWithUs_logo}
                contactus_logo={contactus_logo}
                operating_hour={operating_hour}
                footer_slogan={footer_slogan}
                hasChangesToSave={hasChangesToSave}
                handleChange={stateValueToChange}
                sendingData={sendingData}
                handleSubmit={handleSubmit} />,
        },
    ]

    const deleteService = (idService: string) => {
        const submitData = {
            data: {
                delete: {
                    service: {
                        id: idService,
                    }
                }
            }
        }
        handleSubmit(submitData);
    }

    return <section className='pages-content-container'>
        {
            informationSections.map((section: {
                title: string;
                element: React.JSX.Element;
            }, index: number) => {
                return (<FadeInSection fadeInX key={index}>
                    <section className='pages-content-section'>
                        <h3 className='title2'>{section.title}</h3>
                        <FadeInSection key={activeOption}>
                            {index === activeOption ?
                                section.element :
                                <button
                                    className='custom-button'
                                    type='button'
                                    onClick={() => {
                                        sethasChangesToSave(false);
                                        setWasFetched(false);
                                        setActiveOption(index);
                                    }
                                    }>
                                    {`Manage Content`}
                                </button>
                            }
                        </FadeInSection>
                    </section>
                </FadeInSection>)
            })
        }
        {
            selectedImg && <Modal onClose={() => setSelectedImg(null)}>
                <img src={selectedImg} alt="Modal Content" />
            </Modal>
        }
        {
            serviceToDelete && <ServiceDeletionModal
                serviceToDelete={serviceToDelete}
                sendingData={sendingData}
                onAccept={() => deleteService(serviceToDelete.idService)}
                onCancel={() => setserviceToDelete(null)} />
        }
        {
            sendingData && <Modal>
                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "60vw", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        <svg xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 200 200'>
                            <circle fill='none' stroke-opacity='1' stroke='#22B826' stroke-width='.5' cx='100' cy='100' r='0'>
                                <animate attributeName='r' calcMode='spline' dur='2' values='1;80' keyTimes='0;1' keySplines='0 .2 .5 1' repeatCount='indefinite'></animate>
                                <animate attributeName='stroke-width' calcMode='spline' dur='2' values='0;25' keyTimes='0;1' keySplines='0 .2 .5 1' repeatCount='indefinite'></animate>
                                <animate attributeName='stroke-opacity' calcMode='spline' dur='2' values='1;0' keyTimes='0;1' keySplines='0 .2 .5 1' repeatCount='indefinite'></animate>
                            </circle>
                        </svg>
                    </div>
                </div>
            </Modal>
        }
    </section>
};

export default PagesContentManagement;