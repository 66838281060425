import { ChangeEvent, useState } from "react";
import '../PagesContentManagement/styles.css';
import { uploadFiles } from "services";

type GeneralInformationProps = {
    phone_number: string;
    whatsapp: string;
    email: string;
    homeYoutubeVideoUrl: string;
    youtubeChannelUrl: string;
    map_url: string;
    navbarlogo: string;
    talkWithUs_logo: string;
    contactus_logo: string;
    operating_hour: string[];
    footer_slogan: string;
    hasChangesToSave: boolean;
    sendingData: boolean;
    handleChange: (property: string, value: boolean | string | string[] | object) => void;
    handleSubmit: (data: object) => void;
}

const GeneralInformationSection = (props: GeneralInformationProps) => {
    const {
        phone_number,
        whatsapp,
        email,
        homeYoutubeVideoUrl,
        youtubeChannelUrl,
        map_url,
        navbarlogo,
        talkWithUs_logo,
        contactus_logo,
        operating_hour,
        footer_slogan,
        hasChangesToSave,
        sendingData,
        handleChange,
        handleSubmit,
    } = props;

    const [newNavbarlogo, setnewNavbarlogo] = useState<any | null>(null);
    const [newTalkWithUs_logo, setnewTalkWithUs_logo] = useState<any | null>(null);
    const [newContactus_logo, setnewContactus_logo] = useState<any | null>(null);

    const handleFileChange = (property: string, event: any) => {
        const file = event.target.files[0];
        switch (property) {
            case 'navbarlogo': setnewNavbarlogo(file); break;
            case 'talkWithUs_logo': setnewTalkWithUs_logo(file); break;
            case 'contactus_logo': setnewContactus_logo(file); break;
        }
        handleChange('hasChangesToSave', true);
    }

    const handleGenInfoChange = (property: string, value: string | [], index: number = 0) => {
        handleChange('hasChangesToSave', true);
        if (property === "operating_hour") {
            let newOperatingHour = operating_hour;
            newOperatingHour[index] = value as string;
            handleChange(property, [...newOperatingHour]);
        } else {
            handleChange(property, value as string);
        }
    }

    const onChange = (event: ChangeEvent<HTMLInputElement>, property: string, index: number = 0) => {
        handleGenInfoChange(property, event.target.value, index)
    }

    const onSubmit = (event: any) => {
        event.preventDefault();
        let objToSubmit: any = {
            data: {
                update: {
                    phone_number: phone_number,
                    whatsapp: whatsapp,
                    email: email,
                    homeYoutubeVideoUrl: homeYoutubeVideoUrl,
                    youtubeChannelUrl: youtubeChannelUrl,
                    map_url: map_url,
                    operating_hour: operating_hour,
                    footer_slogan: footer_slogan,
                }
            }
        };

        const filesToUpload = [];
        const fileBaseUrl = `lg_img`;
        if (newNavbarlogo) {
            objToSubmit = {
                ...objToSubmit, data: {
                    ...objToSubmit.data, update: {
                        ...objToSubmit.data.update, navbarlogo: `${fileBaseUrl}/${newNavbarlogo.name}`
                    }
                }
            }
            filesToUpload.push({ file: newNavbarlogo, path: fileBaseUrl});
        }
        if (newTalkWithUs_logo) {
            objToSubmit = {
                ...objToSubmit, data: {
                    ...objToSubmit.data, update: {
                        ...objToSubmit.data.update, talkWithUs_logo: `${fileBaseUrl}/${newTalkWithUs_logo.name}`
                    }
                }
            }
            filesToUpload.push({ file: newTalkWithUs_logo, path: fileBaseUrl});
        }
        if (newContactus_logo) {
            objToSubmit = {
                ...objToSubmit, data: {
                    ...objToSubmit.data, update: {
                        ...objToSubmit.data.update, contactus_logo:  `${fileBaseUrl}/${newContactus_logo.name}`
                    }
                }
            }
            filesToUpload.push({ file: newContactus_logo, path: fileBaseUrl});
        }
        uploadFiles(filesToUpload);
        handleSubmit(objToSubmit);
        const contactusFileField = document.getElementById("new-contactus_logo");
        const talkwithusFileField = document.getElementById("new-talkWithUs_logo");
        const navbarFileField = document.getElementById("new-talkWithUs_logo");
        if (contactusFileField) {
            (contactusFileField as HTMLInputElement).value = '';
        }
        if (talkwithusFileField) {
            (talkwithusFileField as HTMLInputElement).value = '';
        }
        if (navbarFileField) {
            (navbarFileField as HTMLInputElement).value = '';
        }
    }

    return <section className='content-data'>
        <form id="general-info-form" onSubmit={onSubmit}>
            <div className='option-to-edit'>
                <h3 className='title2 gen-opt'>Phone number: </h3>
                <input disabled={sendingData} type="text" value={phone_number} placeholder="Phone number" onChange={(e) => onChange(e, "phone_number")} />
            </div>
            <div className='option-to-edit'>
                <h3 className='title2 gen-opt'>Whatsapp number: </h3>
                <input disabled={sendingData} type="text" value={whatsapp} placeholder="Whatsapp number" onChange={(e) => onChange(e, "whatsapp")} />
            </div>
            <div className='option-to-edit'>
                <h3 className='title2 gen-opt'>email: </h3>
                <input disabled={sendingData} type="text" value={email} placeholder="Email" onChange={(e) => onChange(e, "email")} />
            </div>
            <div className='option-to-edit'>
                <h3 className='title2 gen-opt'>Home Youtube video url: </h3>
                <input disabled={sendingData} type="text" value={homeYoutubeVideoUrl} placeholder="Youtube video url" onChange={(e) => onChange(e, "homeYoutubeVideoUrl")} />
            </div>
            <div className='option-to-edit'>
                <h3 className='title2 gen-opt'>Youtube Channel Url: </h3>
                <input disabled={sendingData} type="text" value={youtubeChannelUrl} placeholder="Youtube channel url" onChange={(e) => onChange(e, "youtubeChannelUrl")} />
            </div>
            <div className='option-to-edit'>
                <h3 className='title2 gen-opt'>Map url: </h3>
                <input disabled={sendingData} type="text" value={map_url} placeholder="Map url link" onChange={(e) => onChange(e, "map_url")} />
            </div>
            <div className='option-to-edit'>
                <h3 className='title2 gen-opt'>Navbar logo: </h3>
                <button type="button" className="custom-button" onClick={() => handleChange('selectedImg', navbarlogo)}>See current logo</button>
                <input disabled={sendingData} accept="image/png, image/jpeg, image/jpg" type="file" placeholder="Nav bar logo" onChange={(e) => handleFileChange('navbarlogo', e)} id={'new-talkWithUs_logo'} />
            </div>
            <div className='option-to-edit'>
                <h3 className='title2 gen-opt'>Talk With Us Logo: </h3>
                <button type="button" className="custom-button" onClick={() => handleChange('selectedImg', talkWithUs_logo)}>See current logo</button>
                <input disabled={sendingData} accept="image/png, image/jpeg, image/jpg" type="file" placeholder="Talk with us logo" onChange={(e) => handleFileChange('talkWithUs_logo', e)} id={'new-talkWithUs_logo'} />
            </div>
            <div className='option-to-edit'>
                <h3 className='title2 gen-opt'>Contact Us Logo: </h3>
                <button type="button" className="custom-button" onClick={() => handleChange('selectedImg', contactus_logo)}>See current logo</button>
                <input disabled={sendingData} accept="image/png, image/jpeg, image/jpg" type="file" placeholder="Contact us logo" onChange={(e) => handleFileChange('contactus_logo', e)} id={'new-contactus_logo'} />
            </div>
            <div className='option-to-edit'>
                <h3 className='title2 gen-opt'>Operating hours: </h3>
                <ol>
                    {
                        operating_hour.map((value: any, index) => {
                            return <li key={index}>
                                <input disabled={sendingData} type="text" value={value} placeholder="oerating hours" onChange={(e) => onChange(e, "operating_hour", index)} />
                            </li>
                        })
                    }
                </ol>
            </div>
            <div className='option-to-edit'>
                <h3 className='title2 gen-opt'>Footer Slogan: </h3>
                <input disabled={sendingData} type="text" value={footer_slogan} placeholder="Footer Slogan" onChange={(e) => onChange(e, "footer_slogan")} />
            </div>
            <div style={{ display: "flex", gap: "5px", paddingTop: "5px" }}>
                <button disabled={!hasChangesToSave || sendingData} className='custom-button' type='submit'>Save Changes</button>
            </div>
        </form>
    </section>
}

export default GeneralInformationSection;