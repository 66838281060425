import { ChangeEvent, useState } from "react";
import '../../../../../PagesContentManagement/styles.css';
import FadeInSection from "containers/FadeInSection";

type ImageListProps = {
    isNew: boolean;
    id: string;
    section: string;
    title: string;
    property: string;
    images: string[];
    newImageFile: null | File;
    sendingData: boolean | undefined;
    handleServInfoChange: (property: string, value: string | [] | File, imageIndex?: number, oper?: string, isNew?: boolean) => void;
    handleFileChange: (property: string, event: any) => void;
}
const ImageList = (props: ImageListProps) => {
    const {
        id,
        isNew,
        section,
        title,
        property,
        images,
        newImageFile,
        sendingData,
        handleServInfoChange,
        handleFileChange,
    } = props;

    return <div className='option-to-edit'>
        {
            (!isNew) ? <h3 className='title2 gen-opt'>{title}</h3> :
                (<h3 className='title2 gen-opt'>New {section} images</h3>)
        }

        <ol style={{ flexDirection: "column", alignItems: "flex-start" }}>
            {
                images.map((serviceImage: any, index: number) => {
                    let imageName = '';
                    let fileToDisplay = serviceImage;
                    if (isNew) {
                        imageName = serviceImage.name;
                        fileToDisplay = URL.createObjectURL(serviceImage);
                    }else{
                        const imageSections = serviceImage.split("/");
                        imageName = imageSections[imageSections.length - 1];
                    }
                    return <li key={index} style={{ listStyle: "none", marginTop: "5px", display: "flex", gap: "5px", alignItems: "flex-start" }}>
                        <button onClick={() => handleServInfoChange("selectedImg", fileToDisplay)} type="button" className="custom-button">See {isNew?'new':'current'} {section} image</button>
                        <button onClick={() => handleServInfoChange(property, '', index, "remove", isNew)} className="custom-button" type="button" aria-label="remove"> X </button>
                        <h3 className='title2 gen-opt' style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{imageName}</h3>
                    </li>
                })
            }
            {
                !isNew && (
                    <li style={{ listStyle: "none", marginTop: "15px", marginBottom: "15px", display: "flex", gap: "5px", alignItems: "flex-start" }}>
                        <button
                            disabled={
                                newImageFile === null || (document.getElementById(`${property}-${id}`) as HTMLInputElement)!.value === ''
                            }
                            className="custom-button"
                            type="button"
                            aria-label="add new" 
                            onClick={() => handleServInfoChange(property, newImageFile as File, -1, "add")} 
                            >
                            Add image
                        </button>
                        <input disabled={sendingData}
                            accept="image/png, image/jpeg, image/jpg"
                            type="file"
                            id={`${property}-${id}`}
                            onChange={(e) => handleFileChange(property, e)} />
                    </li>
                )}
        </ol>
    </div>
}


export type ServiceType = {
    idService: string;
    service_name: string;
    home_service_description: string;
    service_page_description: string;
    service_page_images: string[];
    gallery_page_images: string[]
}

type ServiceOptionProps = {
    updservice_images: any[];
    updgallery_images: any[];
    service: ServiceType;
    sendingData: boolean;
    handleChange: (property: string, value: any, index?: number, oper?: string, isNew?: boolean) => void;
}

const ServiceOption = (props: ServiceOptionProps) => {
    const {
        service,
        updservice_images,
        updgallery_images,
        sendingData,
        handleChange,
    } = props;

    const [newServiceImage, setnewServiceImage] = useState<File | null>(null);
    const [newGalleryImage, setnewGalleryImage] = useState<File | null>(null);

    const handleFileChange = (property: string, event: any) => {
        const file = event.target.files[0];
        switch (property) {
            case 'service_page_images': setnewServiceImage(file); break;
            case 'gallery_page_images': setnewGalleryImage(file); break;
        }
        handleChange('hasChangesToSave', true);
    }

    const handleServInfoChange = (property: string, value: string | [] | File, imageIndex: number = 0, oper: string = "none", isNew: boolean = false) => {
        handleChange(property, value, imageIndex, oper, isNew);
    }
    const onChange = (event: ChangeEvent<HTMLInputElement>, property: string, imageIndex: number = 0, oper: string = "none") => {
        handleServInfoChange(property, event.target.value, imageIndex, oper)
    }
    return <div className="pages-content-section" style={{ marginTop: "10px", marginBottom: "10px" }}>
        <FadeInSection>
            <div className="add-image-panel">
                <h3 className='title2 gen-opt' style={{ width: "100%", textAlign: "center" }}>{service.service_name}</h3>
                <div className='option-to-edit'>
                    <h3 className='title2 gen-opt'>Service name: </h3>
                    <input required disabled={sendingData} type="text" value={service.service_name} placeholder="Service name" onChange={(e) => onChange(e, "service_name")} />
                </div>
                <div className='option-to-edit' style={{ flexDirection: "row" }}>
                    <h3 className='title2 gen-opt'>Home page description: </h3>
                    <input required disabled={sendingData} type="text" value={service.home_service_description} placeholder="Description used in home page" onChange={(e) => onChange(e, "home_service_description")} />
                </div>
                <div className='option-to-edit'>
                    <h3 className='title2 gen-opt'>Service page description: </h3>
                    <input required disabled={sendingData} type="text" value={service.service_page_description} placeholder="Description used in services page" onChange={(e) => onChange(e, "service_page_description")} />
                </div>
                <ImageList
                    id={service.idService}
                    title={"Service page images"}
                    section={"service"}
                    property={"service_page_images"}
                    images={service.service_page_images}
                    newImageFile={newServiceImage}
                    sendingData={sendingData}
                    handleServInfoChange={handleServInfoChange}
                    handleFileChange={handleFileChange}
                    isNew={false}
                />
                {updservice_images.length > 0 && (<ImageList
                    id={service.idService}
                    title={"Service page images"}
                    section={"service"}
                    property={"service_page_images"}
                    images={updservice_images}
                    newImageFile={newServiceImage}
                    sendingData={sendingData}
                    handleServInfoChange={handleServInfoChange}
                    handleFileChange={handleFileChange}
                    isNew={true}
                />)}
                <ImageList
                    id={service.idService}
                    title={"Gallery page images"}
                    section={"gallery"}
                    property={"gallery_page_images"}
                    images={service.gallery_page_images}
                    newImageFile={newGalleryImage}
                    sendingData={sendingData}
                    handleServInfoChange={handleServInfoChange}
                    handleFileChange={handleFileChange}
                    isNew={false}
                />
                {updgallery_images.length > 0 && (<ImageList
                    id={service.idService}
                    title={"Service page images"}
                    section={"service"}
                    property={"gallery_page_images"}
                    images={updgallery_images}
                    newImageFile={newServiceImage}
                    sendingData={sendingData}
                    handleServInfoChange={handleServInfoChange}
                    handleFileChange={handleFileChange}
                    isNew={true}
                />)}
                <div className='option-to-edit'>
                    <button
                        style={{ backgroundColor: "#e80712", color: "#ffffff" }}
                        className='custom-button'
                        type="button"
                        onClick={() => handleChange('serviceToDelete', service)}>
                        Delete service - {service.service_name}
                    </button>
                </div>
            </div>

        </FadeInSection>
    </div>
}

export default ServiceOption;